<template>
  <g :id="node.id">
    <!-- <rect
      v-if="pulsable(node)"
      class="diagram--editor__pulse"
      :fill="pulseColor(node)"
      :stroke="pulseColor(node)"
      :x="node.x"
      :y="node.y"
      rx="3"
      ry="3"
      :width="node.width"
      :height="node.height"
    /> -->
    <!-- <rect
      :fill="color(node)"
      stroke="#000000"
      :stroke-width="selected ? 1 : 0"
      :x="node.x"
      :y="node.y"
      rx="3"
      ry="3"
      :width="node.width"
      :height="node.height"
    /> -->
    <!-- <DiagramNodeTitle
      :x="node.x + 2"
      :y="node.y + 2"
      :width="node.size.width - 4"
      :title="node.data.customName ? node.data.customName : node.title"
      :dragging="dragging"
      :deletable="nodeDeletable"
      :type="node.data.type"
      @mousedown.native.stop="onDragStart"
      @delete="deleteNode"
    /> -->

    <!-- <rect
      fill="#fff"
      :x="node.x + 2"
      :y="node.y + 20"
      rx="3"
      ry="3"
      :width="node.width - 4"
      :height="node.height - 22"
      class="node-light-background"
    /> -->

    <foreignObject
      :x="node.x + 2"
      :y="node.y + 20"
      :width="addMenuOpened ? 420 : 220"
      class="diagram-editor__foreign-object"
      :height="120"
    >
      <body
        xmlns="http://www.w3.org/1999/xhtml"
        class="diagram-editor__node-body-custom"
        :style="{
          background:
            node.data.type === 'action'
              ? '#B2DBFF'
              : node.data.type === 'condition'
              ? '#EDFFC0'
              : node.data.type === 'call-api'
              ? '#FFD68A'
              : node.data.type === 'question'
              ? '#E8A7D5'
              : node.data.type === 'stripe'
              ? '#BFE3B4'
              : '#FFFFFF',
          cursor: dragging ? 'grabbing' : 'pointer',
          height: node.data.type === 'stripe' || node.data.contents && node.data.contents.length > 1
              ? 'auto'
              : '100%',
        }"
        @mousedown="onDragStart"
        @mouseup="onDragEnd"
      >
        <!-- <input /> -->

        <div
          style="word-wrap: break-word;width: 100%;height: 100%;overflow: visible; "
        >
          <div>
            <svg
              v-if="node.data.type != 'entry'"
              :x="1"
              y="1"
              width="14"
              height="14"
              class="diagram-editor__delete-custom to-prevent"
              @click="deleteNode"
            >
              <rect
                x="0"
                y="0"
                width="14"
                height="14"
                rx="2"
                ry="2"
                class="to-prevent"
              />
              <line :x1="1" :y1="1" :x2="10" :y2="10" class="to-prevent" />
              <line :x1="10" :y1="1" :x2="1" :y2="10" class="to-prevent" />
            </svg>
            <div
              v-if="node.data.type == 'entry'"
              style="text-align:center;font-size: 16px;font-weight: 500;  text-transform: uppercase;
"
            >
              {{ node.data.customName ? node.data.customName : node.title }}
            </div>
            <div
              v-else
              style="text-align:center;font-size: 12px;font-weight: 400;"
            >
              {{ node.data.customName ? node.data.customName : node.title }}
            </div>
          </div>

          <div v-for="(content, index) in node.data.contents" :key="index">
            <div v-if="content.type == 'text'" class="content-wrap">
              {{ getContentValue(content.value) }}
            </div>

            <div v-if="content.type == 'card'" class="content-wrap-card">
              <div
                v-if="content.type == 'card'"
                style="display: flex;
                  background-color: white;
                  padding: 12px;
                  border-radius: 12px;
                  color: black;
                  font-weight: 400;
                  border: solid 1px #f1f5f8;
                  align-items: center;
                  justify-content: space-between;
              "
              >
                <span v-if="content.title" style="color:black">
                  {{ 'Card | ' + content.title }}
                </span>

                <span v-else style="color:grey">
                  {{ $t('FLOW.EMPTY_CARD') }}
                </span>
              </div>
            </div>

            <div v-if="content.type == 'carousel'" class="content-wrap">
              <span style="color:grey">
                {{ $t('FLOW.CAROUSEL') }}
              </span>
            </div>

            <div v-if="content.type == 'catalog'" class="content-wrap">
              <span style="color:grey">
                {{ $t('FLOW.CATALOG') + ' : ' + content.catalog_type.name }}
              </span>
            </div>

            <div
              v-if="content.type == 'image' && content.value != ''"
              class="content-wrap content-wrap-uploads"
            >
              <img :src="getImgUrl(content.value)" class="content-image-tag" />

              <span v-if="content.title != ''" class="content-label">{{
                content.title
              }}</span>
            </div>

            <div
              v-if="content.type == 'file' && content.value != ''"
              class="content-wrap content-wrap-uploads"
            >
              <a
                :href="getImgUrl(content.value)"
                style="font-size:10px;"
                target="_blank"
              >
                {{ content.title ? 'File : ' + content.title : 'File' }}
              </a>
            </div>

            <div
              v-if="content.type == 'video' && content.value != ''"
              class="content-wrap content-wrap-video"
            >
              {{ $t('FLOW.VIDEO') }}
              | {{ content.value }}

              <!-- <video width="100" controls>
                <source :src="getImgUrl(content.value)" type="video/mp4" />
              </video> -->
            </div>

            <!-- for conditions -->

            <div
              v-if="content.type == 'condition-response'"
              v-tooltip="{
                content: getConditionString('response', content),
                delay: { show: 2000, hide: 500 },
                show: false,
              }"
              class="condition-wrapper"
            >
              {{ getConditionString('response', content) }}
            </div>
            <div
              v-if="content.type == 'condition-api-response'"
              v-tooltip="{
                content: getConditionString('api-response', content),
                delay: { show: 2000, hide: 500 },
                show: false,
              }"
              class="condition-wrapper"
            >
              {{ getConditionString('api-response', content) }}
            </div>

            <div
              v-if="content.type == 'condition-event_name'"
              v-tooltip="{
                content: getConditionString('label', content),
                delay: { show: 2000, hide: 500 },
                show: getConditionString('event_name', content) > 26,
              }"
              class="condition-wrapper"
            >
              {{ getConditionString('event_name', content) }}
            </div>

            <div
              v-if="content.type == 'condition-event_params'"
              v-tooltip="{
                content: getConditionString('label', content),
                delay: { show: 2000, hide: 500 },
                show: getConditionString('event_param', content) > 26,
              }"
              class="condition-wrapper"
            >
              {{ getConditionString('event_param', content) }}
            </div>

            <div
              v-if="content.type == 'condition-raw'"
              v-tooltip="{
                content: getConditionString('raw', content),
                delay: { show: 2000, hide: 500 },
                show: false,
              }"
              class="condition-wrapper"
            >
              {{ getConditionString('raw', content) }}
            </div>

            <div
              v-if="content.type == 'condition-label'"
              v-tooltip="{
                content: getConditionString('label', content),
                delay: { show: 2000, hide: 500 },
                show: getConditionString('label', content) > 26,
              }"
              class="condition-wrapper"
            >
              {{ getConditionString('label', content) }}
            </div>

            <div
              v-if="content.type == 'condition-variable'"
              v-tooltip="{
                content: getConditionString('variable', content),
                delay: { show: 2000, hide: 500 },
                show: getConditionString('variable', content) > 26,
              }"
              class="condition-wrapper"
            >
              {{ getConditionString('variable', content) }}
            </div>

            <div
              v-if="content.type == 'condition-contact'"
              v-tooltip="{
                content: getConditionString('contact', content),
                delay: { show: 2000, hide: 500 },
                show: getConditionString('contact', content) > 26,
              }"
              class="condition-wrapper"
            >
              {{ getConditionString('contact', content) }}
            </div>

            <div
              v-if="content.type == 'condition-group'"
              class="condition-wrapper"
            >
              {{ $t('FLOW.GROUP_CONDITION') }}
            </div>

            <!-- for conditions ends-->

            <div
              v-if="content.type == 'action-setVariable'"
              class="action-wrapper"
            >
              {{
                content.valueType.id == 'response'
                  ? '⚡ ' +
                    $t('FLOW.VARIABLE') +
                    ' ' +
                    content.name +
                    ' = ' +
                    $t('FLOW.RESPONSE')
                  : '⚡ ' +
                    $t('FLOW.VARIABLE') +
                    ' ' +
                    content.name +
                    ' = ' +
                    $t('FLOW.VARIABLE') +
                    ' ' +
                    content.value
              }}
            </div>

            <div v-if="content.type == 'action-jsEval'" class="action-wrapper">
              {{ '⚡ jsEval : ' + content.value }}
            </div>

            <div
              v-if="content.type == 'action-setAPIVariable'"
              class="action-wrapper"
            >
              {{
                content.valueType.id == 'response'
                  ? '⚡ ' +
                    $t('FLOW.API_RESPONSE_VARIABLE') +
                    ' ' +
                    content.name +
                    ' = ' +
                    $t('FLOW.RESPONSE')
                  : '⚡ ' +
                    $t('FLOW.API_RESPONSE_VARIABLE') +
                    ' ' +
                    content.name +
                    ' = ' +
                    content.value
              }}
            </div>

            <div
              v-if="content.type == 'action-subscriptionPlans'"
              class="action-wrapper"
            >
              {{
                content.valueType.id == 'response'
                  ? '⚡ API Response = ' + $t('FLOW.RESPONSE')
                  : '⚡ API Response = ' +
                    $t('FLOW.VARIABLE') +
                    ' ' +
                    content.value
              }}
            </div>

            <div v-if="content.type == 'action-label'" class="action-wrapper">
              {{ '⚡ ' + content.action.name + ' : ' + content.name }}
            </div>

            <div v-if="content.type == 'action-contact'" class="action-wrapper">
              {{ getActionString(content) }}
            </div>

            <div v-if="content.type == 'action-status'" class="action-wrapper">
              {{ '⚡ Change Status' + ' : ' + content.value.name }}
            </div>
          </div>
          <div
            v-if="node.data.waitForUserMessage == true"
            style="padding: 5px;background-color: white;margin:5px;border-radius: 4px;border: 1px solid #D2D2D2;color: #CECECE; "
          >
            <img
              src="~dashboard/assets/images/flow-new/wait-for-user-input-icon.png"
              style="width:16px"
            />

            {{ $t('FLOW.WAIT_FOR_USER_MESSAGE') }}
          </div>

          <div
            v-if="
              node.data.type == 'condition' && node.data.contents.length == 1
            "
            class="condition-wrapper"
          >
            {{ $t('FLOW.ALWAYS') }}
          </div>

          <div
            v-if="
              node.data.type == 'condition' && node.data.contents.length > 1
            "
            class="condition-wrapper"
          >
            {{ $t('FLOW.OTHERWISE') }}
          </div>

          <!-- CALL API DEFAULT CONTENT START-->

          <div v-if="node.data.type === 'call-api'" class="call-api-wrapper">
            {{ $t('FLOW.ON_SUCCESS') }}
          </div>

          <div v-if="node.data.type === 'call-api'" class="call-api-wrapper">
            {{ $t('FLOW.ON_FAILURE') }}
          </div>

          <!-- CALL API DEFAULT CONTENT END-->

          <!-- STRIPE NODE DEFAULT CONTENT START -->

          <div v-if="node.data.type === 'stripe'" class=" stripe-wrapper">
            {{ $t('FLOW.ON_SUCCESS') }}

            <div v-if="node.data.contents.length > 0" style="color:black">
              {{ node.data.contents[0].successUrl}}
            </div>
          </div>

          <!-- STRIPE NODE DEFAULT CONTENT END -->

          <!-- QUESTION_NODE CONTENT START-->
          <div v-if="node.data.type === 'question'">
            <div v-for="(subNode, subNodeIndex) in node.data.subNodes" :key="subNodeIndex">
              <div v-for="(content, contentIndex) in subNode.data.contents" :key="contentIndex">
                <!-- <div
                  v-if="content.type == 'condition-contact'"
                  v-tooltip="{
                    content: getConditionString('contact', content),
                    delay: { show: 2000, hide: 500 },
                    show: getConditionString('contact', content) > 26,
                  }"
                  class="condition-wrapper"
                >
                  {{ getConditionString('contact', content) }}
                </div> -->
                <div v-if="content.type == 'card'" class="content-wrap-card">
                  <div
                    v-if="content.type == 'card'"
                    style="display: flex;
                      background-color: white;
                      padding: 12px;
                      border-radius: 12px;
                      color: black;
                      font-weight: 400;
                      border: solid 1px #f1f5f8;
                      align-items: center;
                      justify-content: space-between;
                  "
                  >
                    <span v-if="content.title" style="color:black">
                      {{ 'Card | ' + content.title }}
                    </span>
                    <span v-else style="color:grey">
                      {{ $t('FLOW.EMPTY_CARD') }}
                    </span>
                  </div>
                </div>
                <!-- <div v-if="content.type == 'action-contact'" class="action-wrapper">
                  {{ getActionString(content) }}
                </div> -->
              </div>
            </div>

          </div>

          <!-- QUESTION_NODE CONTENT END-->

          <!--
          <div
            v-if="node.data.type == 'content'"
            class="add-box"
            style="min-height: 100px;"
          >
            <h6 class="text">+ {{ $t('FLOW.NODE_ADD_CONTENT') }}</h6>
          </div> -->

          <!-- <div
            v-if="node.data.type == 'condition'"
            class="add-box"
            style="min-height: 100px;"
          >
            <h6 class="text">+ {{ $t('FLOW.NODE_ADD_CONDITION') }}</h6>
          </div> -->

          <div
            v-if="node.data.endFlowAction == true"
            style="padding: 5px;background-color: white;margin:5px;border-radius: 4px;border: 1px solid #D2D2D2;color: #CECECE; "
          >
            <img
              src="~dashboard/assets/images/flow-new/end-flow-sign.png"
              style="width:16px;filter:opacity(0.2)"
            />
            {{ $t('FLOW.END_FLOW') }}
          </div>

          <!-- <div
            v-if="node.data.type == 'action'"
            class="add-box"
            style="min-height: 100px;"
          >
            <h6 class="text">+ {{ $t('FLOW.NODE_ADD_ACTION') }}</h6>
          </div> -->

          <div v-if="node.data.type == 'entry'" class="start-placeholder">
            <h6 class="text">
              {{ $t('FLOW.START_NODE_PLACEHOLDER') }}
            </h6>
          </div>
        </div>
        <a
          href="#"
          class="float-variant to-prevent"
          @click.stop="addButtonClicked"
        >
          <img
            src="~dashboard/assets/images/flow-new/add-icon.png"
            :class="[
              showPopup
                ? 'image-float-small image-small-float-variant to-prevent'
                : 'image-float-small image-small-float to-prevent',
            ]"
            width="12"
          />
        </a>

        <div
          v-if="showPopup"
          class="float-box-small to-prevent"
          style="cursor:default"
        >
          <h4 class="title to-prevent">{{ $t('FLOW.ADD_NODE') }}</h4>
          <div
            class="item to-prevent"
            style="background-color:white;margin-bottom: 5px;"
            @click="menuItemClick('content')"
          >
            <img
              src="~dashboard/assets/images/flow-new/menu-icon-content.svg"
              class="to-prevent"
            />
            <h6 class="text to-prevent">
              {{ $t('FLOW.NODE_ADD_CONTENT') }}
            </h6>
            <span></span>
          </div>
          <div
            class="item to-prevent"
            style="background-color:#EDFFC0;margin-bottom: 5px;"
            @click="menuItemClick('condition')"
          >
            <img
              src="~dashboard/assets/images/flow-new/menu-icon-condition.svg"
              class="to-prevent"
            />
            <h6 class="text to-prevent">
              {{ $t('FLOW.NODE_ADD_CONDITION') }}
            </h6>
            <span></span>
          </div>
          <div
            class="item to-prevent"
            style="background-color:#B2DBFF;margin-bottom: 5px;"
            @click="menuItemClick('action')"
          >
            <img
              src="~dashboard/assets/images/flow-new/menu-icon-action.svg"
              class="to-prevent"
            />
            <h6 class="text to-prevent">
              {{ $t('FLOW.NODE_ADD_ACTION') }}
            </h6>
            <span></span>
          </div>
          <div
            class="item to-prevent"
            style="background-color:#FFD68A; margin-bottom: 5px;"
            @click="menuItemClick('call-api')"
          >
            <img
              src="~dashboard/assets/images/flow-new/api-call.svg"
              class="to-prevent"
            />
            <h6 class="text to-prevent">
              {{ $t('FLOW.NODE_ADD_CALL_API') }}
            </h6>
            <span></span>
          </div>
          <div
            class="item to-prevent"
            style="background-color:#E8A7D5; margin-bottom: 5px;"
            @click="menuItemClick('question')"
          >
            <img
              src="~dashboard/assets/images/flow-new/api-call.svg"
              class="to-prevent"
            />
            <h6 class="text to-prevent">
              {{ $t('FLOW.NODE_ADD_QUESTION') }}
            </h6>
            <span></span>
          </div>
          <div
            class="item to-prevent"
            style="background-color:#BFE3B4"
            @click="menuItemClick('stripe')"
          >
            <img
              src="~dashboard/assets/images/flow-new/api-call.svg"
              class="to-prevent"
            />
            <h6 class="text to-prevent">
              {{ $t('FLOW.STRIPE') }}
            </h6>
            <span></span>
          </div>
        </div>
      </body>
    </foreignObject>
    <!-- this svg is showing all ports -->
    <svg
      :x="node.x - 4"
      :y="node.y + 33"
      :width="node.width > 200 ? node.width + 8 : 220"
    >
      <DiagramPort
        v-for="([port, portTitle], portIndex) in portsIn"
        :id="node.id + '_in_' + port"
        :key="node.id + '_in_' + port"
        :title="portTitle"
        :width="portIndex + 1 > portsOut.length ? node.width : node.width / 2"
        :x="22"
        :y="portIndex * 30 + 43"
        :disabled="portsInDisabled[port]"
        :available="portsInAvailable[port]"
        :hovered="isHoveredPort('in', port)"
        align="start"
        @mousedown="mouseDownPort('in', port, $event)"
        @mouseenter="mouseEnterPort('in', port, $event)"
        @mouseleave="mouseLeavePort('in', port, $event)"
      />

        <!-- portsOut reactivity was not working, so used watch to update new variable and render that variable. __.__ -->
       <!-- v-for="([port, portTitle], portIndex) in portsOut" -->
      <DiagramPort
        v-for="([port, portTitle], portIndex) in portsOutList"
        :id="node.id + '_out_' + port"
        :key="node.id + '_out_' + port"
        :title="portTitle"
        :width="node.width > 200 ? node.width + 8 : 210"
        :x="2"
        :y="portIndex * 30 + 43"
        :disabled="portsOutDisabled[port]"
        :available="portsOutAvailable[port]"
        :hovered="isHoveredPort('out', port)"
        align="end"
        @mousedown="mouseDownPort('out', port, $event)"
        @mouseenter="mouseEnterPort('out', port, $event)"
        @mouseleave="mouseLeavePort('out', port, $event)"
      />
    </svg>
  </g>
</template>
<script>
import Node from '../models/Node';
import DiagramPort from './DiagramPort';
import isEqual from 'lodash/isEqual';
import FlowApi from '../../../FlowApi';
let startX;
let startY;
let threshold = 1;
export default {
  name: 'DiagramNode',

  components: {
    DiagramPort,
  },

  props: {
    node: { type: Node, required: true },
    color: { type: Function, required: true },
    pulseColor: { type: Function, required: true },
    pulsable: { type: Function, required: true },
    portDisabled: { type: Function, required: true },
    portAvailable: { type: Function, required: true },
    deletable: { type: Function, required: true },
    activePort: { type: Object, default: null },
    hoveredPort: { type: Object, default: null },
    selected: { type: Boolean, default: false },
    dragging: { type: Boolean, default: false },
    botid: { require: true },
  },

  data() {
    return {
      nodeStrokeWidth: 0,
      titleFillOpacity: 0.25,
      drag: false,
      showPopup: false,
      wasDragged: false,
      addMenuOpened: false,
      portsOutList: Object.entries(this.node?.portsOut)
    };
  },

  computed: {
    portsIn() {
      return Object.entries(this.node.portsIn);
    },
    portsOut() {
    // console.log({[this.node.id]:this.node });
      return Object.entries(this.node.portsOut);
    },
    portsInDisabled() {
      return Object.keys(this.node.portsIn).reduce(
        (acc, port) => ({
          ...acc,
          [port]: this.portDisabled({ id: this.node.id, type: 'in', port }),
        }),
        {}
      );
    },
    portsOutDisabled() {
      return Object.keys(this.node.portsOut).reduce(
        (acc, port) => ({
          ...acc,
          [port]: this.portDisabled({ id: this.node.id, type: 'out', port }),
        }),
        {}
      );
    },
    portsInAvailable() {
      return Object.keys(this.node.portsIn).reduce(
        (acc, port) => ({
          ...acc,
          [port]: this.activePort && this.isAvailablePort('in', port),
        }),
        {}
      );
    },
    portsOutAvailable() {
      return Object.keys(this.node.portsOut).reduce(
        (acc, port) => ({
          ...acc,
          [port]: this.activePort && this.isAvailablePort('out', port),
        }),
        {}
      );
    },
    portsHeight() {
      return this.node.portsHeight;
    },
    slotWidth() {
      return this.node.size.width > 200 ? this.node.size.width : 200;
    },
    slotHeight() {
      // отступ - заготовок - отступ
      return this.node.size.height - 22;
    },
    nodeDeletable() {
      return this.deletable(this.node);
    },
  },
  watch:{
    node: {
      // when change in node, update the portsOut __.__
      handler(newVal) {
        this.portsOutList = Object.entries(newVal.portsOut);

        this.$forceUpdate();  // remove this
      },
      deep: true
    },
  },

  methods: {
    menuItemClick(type) {
      console.log({ node: this.node, type: type } ,'>>> DiagramNode.vue <<<','line-776')
      this.$emit('add-btn-clicked', { node: this.node, type: type });
      this.showPopup = false;
    },
    addButtonClicked() {
      this.showPopup = !this.showPopup;
      // let parent = event.currentTarget.parentElement.parentElement;
      // let preX = parent.getAttribute('x');
      // parent.setAttribute('x', preX - 0.01);
      this.addMenuOpened = !this.addMenuOpened;
    },
    getActionString(content) {
      let varName = '';

      if (content.value.id === 'variable') {
        varName = content.name !== '' ? " '" + content.name + "'" : '';
      }
      if (content.attrType.id === 'standard') {
        return (
          '⚡ Contact - Standard : ' +
          content.attr.name +
          ' = ' +
          content.value.name +
          varName
        );
      }
      if (content.attrType.id === 'custom') {
        return (
          '⚡ Contact - Custom : ' +
          content.customAttr +
          ' = ' +
          content.value.name +
          varName
        );
      }
      return '⚡ Contact';
    },
    parseOperator(op) {
      let operatorParsed = '';

      switch (op) {
        case 'op-equal':
          operatorParsed = 'equal to';
          break;

        case 'op-not-equal':
          operatorParsed = 'not equal to';
          break;

        case 'op-contains':
          operatorParsed = 'contains';
          break;

        case 'op-does-not-contains':
          operatorParsed = 'does not contain';
          break;

        case 'op-gt':
          operatorParsed = 'greater than';
          break;
        case 'op-lt':
          operatorParsed = 'less than';
          break;

        case 'op-gte':
          operatorParsed = 'greater than or equal to';
          break;
        case 'op-lte':
          operatorParsed = 'less than or equal to';
          break;
        case 'op-x-null':
          operatorParsed = 'is Null';
          break;
        case 'op-x-not-null':
          operatorParsed = 'is not Null';
          break;
        case 'op-x-empty':
          operatorParsed = 'is Empty';
          break;
        case 'op-x-not-empty':
          operatorParsed = 'is not Empty';
          break;
        case 'op-has-property':
          operatorParsed = 'has Property ';
          break;
        default:
          operatorParsed = '';
      }

      return operatorParsed;
    },
    getConditionString(type, content) {
      if (type === 'response') {
        let text = '';
        if (content.operandB && content.operandB.id === 'op-variable') {
          text = ' Variable ';
        }
        return `Response ${content.operator.name}${text} ${content.value} `;
      }
      if (type === 'api-response') {
        let text = '';
        if (content.operandB.id === 'op-variable') {
          text = ' Variable ';
        }
        return `API Response ${content.operator.name}${text} ${content.value} `;
      }
      if (type === 'event_name') {
        let text = '';
        if (content.operandB.id === 'op-variable') {
          text = ' Variable ';
        }
        return `Event Name ${content.operator.name}${text} ${content.value} `;
      }

      if (type === 'event_param') {
        let text = '';
        if (content.operandB.id === 'op-variable') {
          text = ' Variable ';
        }
        return `Event Param ${content.paramName} ${content.operator.name}${text} ${content.value} `;
      }

      if (type === 'raw') {
        return `Raw Expression : ${content.value} `;
      }
      if (type === 'response') {
        let text = '';
        if (content.operandB && content.operandB.id === 'op-variable') {
          text = ' Variable ';
        }
        return `Response ${content.operator.name}${text} ${content.value} `;
      }

      if (type === 'label') {
        let text = '';
        if (content.operandB.id === 'op-variable') {
          text = ' Variable ';
        }
        return `Label ${content.operator.name}${text} ${content.value} `;
      }

      if (type === 'variable') {
        let operatorParsed = this.parseOperator(content.operator.id);

        return `Variable ${content.name} ${operatorParsed} ${content.value} `;
      }

      if (type === 'contact') {
        let operand1 = '';

        if (content.attrType.id === 'standard') {
          operand1 = content.attr.name;
        }

        if (content.attrType.id === 'custom') {
          operand1 = content.customAttr;
        }

        // let operatorParsed = '';

        let operatorParsed = this.parseOperator(content.operator.id);
        if (
          content.attrType.id === 'custom' &&
          content.operator.id === 'op-has-property'
        ) {
          return `Contact Custom ${operatorParsed} ${operand1} `;
        }

        return `Contact '${operand1}' ${operatorParsed} ${content.value} `;
      }

      return 'Condition';
    },
    getContentValue(value) {
      return value === '' ? 'EMPTY TEXT' : value;
    },

    getImgUrl(imageName) {
      return FlowApi.getImgUrl(imageName, this.botid);
    },
    nodeClicked() {
      if (this.node.data.type !== 'entry') {
        this.$emit('clicked-node', this.node);
      }
    },
    isAvailablePort(type, port) {
      const data = { id: this.node.id, type, port };
      const activePort = { ...this.activePort };
      const hoveredPort = this.hoveredPort ? { ...this.hoveredPort } : null;
      const hoveredIsActive = hoveredPort
        ? isEqual(activePort, hoveredPort)
        : false;
      return (
        !hoveredIsActive &&
        !isEqual(activePort, data) &&
        this.portAvailable({
          ...data,
          activePort: { ...this.activePort },
        })
      );
    },

    deleteNode() {
      this.$emit('delete', this.node.id);
    },

    onDragEnd(e) {
      const diffX = Math.abs(e.pageX - startX);
      const diffY = Math.abs(e.pageY - startY);

      if (diffX < threshold && diffY < threshold) {
        if (
          this.node.data.type !== 'entry' &&
          !e.target.classList.contains('to-prevent')
        ) {
          this.$emit('clicked-node', this.node);
        }
      }
    },
    onDragStart(e) {
      startX = e.pageX;
      startY = e.pageY;

      // e.preventDefault();
      this.$emit('drag-start', {
        node: this.node,
        eX: e.x,
        eY: e.y,
      });
    },
    onResizeStart(e) {
      this.$emit('resize-start', {
        node: this.node,
        eX: e.x,
        eY: e.y,
      });
    },

    isHoveredPort(type, port) {
      return (
        this.hoveredPort &&
        this.node.id === this.hoveredPort.id &&
        port === this.hoveredPort.port &&
        type === this.hoveredPort.type
      );
    },

    mouseDownPort(type, port) {
      const portIsDisabled =
        type === 'in'
          ? this.portsInDisabled[port]
          : this.portsOutDisabled[port];
      if (!portIsDisabled) {
        this.$emit('active-port', {
          id: this.node.id,
          port: port,
          type: type,
        });
      }
    },

    mouseEnterPort(type, port) {
      this.$emit('hovered-port', {
        id: this.node.id,
        port: port,
        type: type,
      });
    },
    mouseLeavePort() {
      this.$emit('hovered-port', null);
    },
  },
};
</script>

<style>
.call-api-wrapper {
  padding: 5px;
  margin: 5px;
  white-space: nowrap;
  overflow: hidden;
  background: white;
  border-radius: 4px;
}
.condition-wrapper {
  padding: 5px;
  margin: 5px;
  white-space: nowrap;
  overflow: hidden;
  background: white;
  border-radius: 4px;
}
.content-wrap {
  padding: 5px;
  margin: 5px;
  white-space: nowrap;
  overflow: hidden;
  background: white;
  border-radius: 4px;
}
.action-wrapper {
  padding: 5px;
  margin: 5px;
  /* white-space: nowrap; */
  overflow: hidden;
  border-radius: 4px;

  /* border: 1px solid #000; */
  background: white;
}
.stripe-wrapper {
  background-color: #BFE3B4;
  padding: 5px;
  margin: 5px;
}
</style>
