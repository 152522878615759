<template>
  <div class="column content-box onboard_container">
    <section class="row">
      <div class="column content-box">

        <section class="business_container">
          <div class="banner_heading">
              <h3 class="page-title"> {{$t('ARRANGE.BUSINESS_TYPE.TITLE')}}</h3>
          </div>
          <div>
            <p class="sub-block-title sub_title">{{$t('ARRANGE.BUSINESS_TYPE.CUSTOMER_LEVEL')}}</p>
            <RadioList
              :options="businessTypeOprions"
              v-model="selectedBysinessTypeIndex"
              @change="onBusinessTypeChange"
            />
          </div>
          <div>
            <label for="">{{$t('ARRANGE.BUSINESS_TYPE.INDUSTRY_LEVEL')}}</label>
            <multiselect
              v-model="selectedService"
              :options="services"
              track-by="id"
              label="industry"
              :disabled="false"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :hide-selected="true"
              :tag-placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES')"
              :taggable="true"
              :placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER')"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            />
          </div>
          <div>
            <woot-input
              v-model.trim="average_order"
              type="number"
              :class="{ error: $v.average_order.$error }"
              :label="$t('ARRANGE.BUSINESS_TYPE.ORDER_LEVEL')"
              :placeholder="'500'"
              :error="
                $v.average_order.$error
                  ? $t('ARRANGE.BUSINESS_TYPE.ORDER_ERR')
                  : ''
              "
              @blur="$v.average_order.$touch"
            />
          </div>
          <div class="btn_wrap">
            <woot-button
              color-scheme="primary"
              :is-loading="isLoading"
              :is-disabled="isLoading"
              @click="onBusinessSubmit"
            >
              {{$t('ARRANGE.BUSINESS_TYPE.BTN')}}
            </woot-button>
          </div>
        </section>

      </div>
    </section>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import {businessTypes,services} from "./constant"
import RadioList from './RadioList.vue';
import alertMixin from 'shared/mixins/alertMixin';
import AccountAPI from "../../../api/account"
import { mapGetters } from 'vuex';
import {getParamsFromUrl, frontendURL} from "../../../helper/URLHelper"
import {SIGNUP_FLOW} from "../../../helper/demoAutomationHelper"
import endPoints from '../../../api/endPoints';

export default {
  components: {
    RadioList,
  },
  mixins: [alertMixin],
  data() {
    return {
      selectedBysinessTypeIndex: 1,
      selectedService: null,
      services,
      isLoading: false,
      average_order: 0,
    };
  },
  computed:{
    ...mapGetters({
      currentUser: 'getCurrentUser',
      userPlan: 'accounts/getUserPlan',
      getCurrentAccount: 'accounts/getAccount',
    }),
    businessTypeOprions(){
      return businessTypes.map(el=>({...el,label:el.title,description: el.info}))
    },
  },
  validations: {
    average_order: {
      required,
      isNumeric(value){
        return /^[0-9]+$/.test(value);
      }
    },
  },
  methods: {
    async onBusinessSubmit(){
      const query = getParamsFromUrl();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (!this.selectedService) {
        this.showAlert("Please select a service");
        return
      }

      try {
        this.isLoading = true;

        const business = businessTypes[this.selectedBysinessTypeIndex]
        const contactAttributes = {
          distribution: business.id,
          sector: this.selectedService[business.id],
          industry: this.selectedService.industry,
          average_order:  parseInt(this.average_order),
        }
        const {data:attrRes} = await AccountAPI.setCustomAttributeToAdminContact(contactAttributes);
        await this.$store.dispatch('accounts/get'); // get updated account
        const nextsignupflow = {
            account_id:this.currentUser.account_id,
            isNewSignup:true,
            current_step: SIGNUP_FLOW.FLOWS.select_plan
          }
        SIGNUP_FLOW.setFlow(nextsignupflow)

        // this.$router.push({
        //   path: `/app/accounts/${this.currentUser.account_id}/settings/subscription-plans`,
        //   query: {...query}
        // });
        // window.location = frontendURL(`accounts/${this.currentUser.account_id}/settings/subscription-plans`,query)
        window.location = frontendURL(endPoints('subscriptionPlans').url(this.currentUser.account_id),query)

        this.showAlert("Business update successful!");
      } catch (error) {
        console.log(error);
        this.showAlert("Failed to update business");
      } finally{
        this.isLoading = false;
      }

    },
    onBusinessTypeChange(index) {
      console.log('Selected business type:', index);
    },
  },
}
</script>

<style scoped lang="scss">
.business_container{
  min-height: 100vh;
  width: fit-content;
  margin: auto;

  .sub_title{
    font-weight: 600;
    margin-top: 16px;
  }
  .btn_wrap{
    margin-top: 20px;
  }
}
</style>