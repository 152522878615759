import { render, staticRenderFns } from "./ContestEventPopup.vue?vue&type=template&id=875c3490&scoped=true&"
import script from "./ContestEventPopup.vue?vue&type=script&lang=js&"
export * from "./ContestEventPopup.vue?vue&type=script&lang=js&"
import style0 from "./ContestEventPopup.vue?vue&type=style&index=0&id=875c3490&lang=scss&scoped=true&"
import style1 from "./ContestEventPopup.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "875c3490",
  null
  
)

export default component.exports