import AuthAPI from '../api/auth';
import BaseActionCableConnector from '../../shared/helpers/BaseActionCableConnector';
import { newMessageNotification } from 'shared/helpers/AudioNotificationHelper';

class ActionCableConnector extends BaseActionCableConnector {
  constructor(app, pubsubToken) {
    const { websocketURL = '' } = window.peasyConfig || {};
    super(app, pubsubToken, websocketURL);
    this.CancelTyping = [];
    this.events = {
      'message.created': this.onMessageCreated,
      'message.updated': this.onMessageUpdated,
      'conversation.created': this.onConversationCreated,
      'conversation.status_changed': this.onStatusChange,
      'user:logout': this.onLogout,
      'page:reload': this.onReload,
      'assignee.changed': this.onAssigneeChanged,
      'conversation.typing_on': this.onTypingOn,
      'conversation.typing_off': this.onTypingOff,
      'conversation.contact_changed': this.onConversationContactChange,
      'presence.update': this.onPresenceUpdate,
      'contact.deleted': this.onContactDelete,
      'contact.updated': this.onContactUpdate,
      'conversation.mentioned': this.onConversationMentioned,

      // shop app action cable hook
      // pending: 1, cancelled: 2, packed: 3, out_for_delivery: 4, refunded: 5, completed: 6, paid: 7, created
      'order.pending': this.onOrderPending,
      'order.cancelled': this.onOrderCancelled,
      'order.packed': this.onOrderPacked,
      'order.out_for_delivery': this.onOrderOutForDelivery,
      'order.refunded': this.onOrderRefunded,
      'order.completed': this.onOrderCompleted,
      'order.paid': this.onOrderPaid,
      'order.created': this.onOrderCreated,
      'order.missed': this.onOrderMissed,
    };
  }

  isAValidEvent = data => {
    return this.app.$store.getters.getCurrentAccountId === data.account_id;
  };

  onMessageUpdated = data => {
    this.app.$store.dispatch('updateMessage', data);
  };

  onPresenceUpdate = data => {
    this.app.$store.dispatch('contacts/updatePresence', data.contacts);
    this.app.$store.dispatch('agents/updatePresence', data.users);
    this.app.$store.dispatch('setCurrentUserAvailability', data.users);
  };

  onConversationContactChange = payload => {
    const { meta = {}, id: conversationId } = payload;
    const { sender } = meta || {};
    if (conversationId) {
      this.app.$store.dispatch('updateConversationContact', {
        conversationId,
        ...sender,
      });
    }
  };

  onAssigneeChanged = payload => {
    const { id } = payload;
    if (id) {
      this.app.$store.dispatch('updateConversation', payload);
    }
    this.fetchConversationStats();
  };

  onConversationCreated = data => {
    this.app.$store.dispatch('addConversation', data);
    this.fetchConversationStats();
  };

  onLogout = () => AuthAPI.logout();

  onMessageCreated = data => {
    newMessageNotification(data);
    this.app.$store.dispatch('addMessage', data);
  };

  onReload = () => window.location.reload();

  onStatusChange = data => {
    this.app.$store.dispatch('updateConversation', data);
    this.fetchConversationStats();
  };

  onTypingOn = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store.dispatch('conversationTypingStatus/create', {
      conversationId,
      user,
    });
    this.initTimer({ conversation, user });
  };

  onTypingOff = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store.dispatch('conversationTypingStatus/destroy', {
      conversationId,
      user,
    });
  };

  onConversationMentioned = data => {
    this.app.$store.dispatch('addMentions', data);
  };

  clearTimer = conversationId => {
    const timerEvent = this.CancelTyping[conversationId];

    if (timerEvent) {
      clearTimeout(timerEvent);
      this.CancelTyping[conversationId] = null;
    }
  };

  initTimer = ({ conversation, user }) => {
    const conversationId = conversation.id;
    // Turn off typing automatically after 30 seconds
    this.CancelTyping[conversationId] = setTimeout(() => {
      this.onTypingOff({ conversation, user });
    }, 30000);
  };

  fetchConversationStats = () => {
    bus.$emit('fetch_conversation_stats');
  };

  onContactDelete = data => {
    this.app.$store.dispatch(
      'contacts/deleteContactThroughConversations',
      data.id
    );
    this.fetchConversationStats();
  };

  onContactUpdate = data => {
    this.app.$store.dispatch('contacts/updateContact', data);
  };

  // shop app action to chat catalog
  onOrderPending = data => {
    this.onOrderUpdateHandle(data)
  };
  onOrderCancelled = data => {
    this.onOrderUpdateHandle(data)
  };
  onOrderPacked = data => {
    this.onOrderUpdateHandle(data)
  };
  onOrderOutForDelivery = data => {
    this.onOrderUpdateHandle(data)
  };
  onOrderRefunded = data => {
    this.onOrderUpdateHandle(data)
  };
  onOrderCompleted = data => {
    this.onOrderUpdateHandle(data)
  };
  onOrderPaid = data => {
    this.onOrderUpdateHandle(data)
  };
  onOrderCreated = data => {
    // insert a new order
    this.app.$store.dispatch('orders/createOrder', data);
  };
  onOrderMissed = data => {
    console.log(data,"onOrderMissed");
    bus.$emit('actionCableEvent_OrderMissed',data)
  };
  onOrderUpdateHandle = (data)=>{
    const orderData = {
      order_id:data.id,
      payload:{
        order_status: data.order_status,
      }
    };

    this.app.$store.dispatch('orders/UpdateOrderById', orderData);
  }

}

export default {
  init() {
    if (AuthAPI.isLoggedIn()) {
      const actionCable = new ActionCableConnector(
        window.WOOT,
        AuthAPI.getPubSubToken()
      );
      return actionCable;
    }
    return null;
  },
};
