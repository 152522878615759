import VueRouter from 'vue-router';

import auth from '../api/auth';
import login from './login/login.routes';
import dashboard from './dashboard/dashboard.routes';
import authRoute from './auth/auth.routes';
import { frontendURL } from '../helper/URLHelper';
import PMConnectAPI from './dashboard/settings/bot/PMConnectAPI';
import AccountAPI from '../api/account';
import authAPI from '../api/auth';
import { DEFAULT_REDIRECT_URL } from '../constants';
// import {SIGNUP_FLOW} from "dashboard/helper/demoAutomationHelper.js"
import {SIGNUP_FLOW} from "../helper/demoAutomationHelper"

const routes = [
  ...login.routes,
  ...dashboard.routes,
  ...authRoute.routes,
  {
    path: '/',
    redirect: '/app',
  },
];

window.roleWiseRoutes = {
  agent: [],
  administrator: [],
};

const getUserRole = ({ accounts } = {}, accountId) => {
  const currentAccount = accounts.find(account => account.id === accountId);
  return currentAccount ? currentAccount.role : null;
};

// generateRoleWiseRoute - updates window object with agent/admin route
const generateRoleWiseRoute = route => {
  route.forEach(element => {
    if (element.children) {
      generateRoleWiseRoute(element.children);
    }
    if (element.roles) {
      element.roles.forEach(roleEl => {
        window.roleWiseRoutes[roleEl].push(element.name);
      });
    }
  });
};
// Create a object of routes
// accessible by each role.
// returns an object with roles as keys and routeArr as values
generateRoleWiseRoute(routes);

export const router = new VueRouter({ mode: 'history', routes });

const unProtectedRoutes = ['login', 'auth_signup', 'auth_reset_password'];

const authIgnoreRoutes = [
  'auth_confirmation',
  'pushBack',
  'auth_password_edit',
  // 'business_category',
];

function routeIsAccessibleFor(route, role) {
  return window.roleWiseRoutes[role].includes(route);
}

const routeValidators = [
  {
    protected: false,
    loggedIn: true,
    handler: () => {
      const user = auth.getCurrentUser();
      return `accounts/${user.account_id}/dashboard`;
    },
  },
  {
    protected: true,
    loggedIn: false,
    handler: () => 'login',
  },
  {
    protected: true,
    loggedIn: true,
    handler: to => {
      const user = auth.getCurrentUser();
      const userRole = getUserRole(user, Number(to.params.accountId));
      const isAccessible = routeIsAccessibleFor(to.name, userRole);
      return isAccessible ? null : `accounts/${to.params.accountId}/dashboard`;
    },
  },
  {
    protected: false,
    loggedIn: false,
    handler: () => null,
  },
];

export const validateAuthenticateRoutePermission = (to, from, next) => {
  const isLoggedIn = auth.isLoggedIn();
  const isProtectedRoute = !unProtectedRoutes.includes(to.name);
  const strategy = routeValidators.find(
    validator =>
      validator.protected === isProtectedRoute &&
      validator.loggedIn === isLoggedIn
  );
  const nextRoute = strategy.handler(to);
  return nextRoute ? next(frontendURL(nextRoute)) : next();
};

const validateRouteAccess = (to, from, next) => {
  if (
    window.peasyConfig.signupEnabled !== 'true' &&
    to.meta &&
    to.meta.requireSignupEnabled
  ) {
    const user = auth.getCurrentUser();
    next(frontendURL(`accounts/${user.account_id}/dashboard`));
  }

  if (authIgnoreRoutes.includes(to.name)) {
    return next();
  }
  return validateAuthenticateRoutePermission(to, from, next);
};

// protecting routes
router.beforeEach(async (to, from, next) => {
  // console.log(to);
  const currentUser = auth.getCurrentUser();
  let toAppend = '';
  if (to.path === '/app/pm-auth-callback') {
    localStorage.setItem('pm-auth-token', to.query.auth);
    let genericTokenResponse = await PMConnectAPI.getGenericAcesssToken();
    if (genericTokenResponse?.key) {
      const user = auth.getCurrentUser();
      const payload = {
        email: user.email,
        account_id: user.pm_account_id,
        pm_access_token: genericTokenResponse?.key,
      };
      await AccountAPI.updateToken(payload);
    }

    window.close();
  }
  if (to.path === '/app/peasy-integrate-callback') {
    const { fb_access_token, pm_access_token } = to.query;

    console.log('peasy integrate ', fb_access_token, pm_access_token);

   // const userUpdateResponse = await authAPI.profileUpdate({
   //   pm_access_token: pm_access_token,
  //  });
    const user = auth.getCurrentUser();

    //console.log('userUpdateResponse', userUpdateResponse);
    let accountUpdateResponse = await AccountAPI.update(
      user.account_id,
      {
        fb_access_token: fb_access_token,
        pm_access_token: pm_access_token,
      },
      true
    );
    console.log('accountUpdateResponse', accountUpdateResponse);

    // Redirect back to peasy marketing after saving fb_access_token and pm_access_token
    window.location = PMConnectAPI.getBaseURL();
  }
  if (to.path === '/app/external' && to.query.buy_now_plan_id) {
    toAppend = '?buy_now_plan_id=' + to.query.buy_now_plan_id;

    // add query params for grant user
    const {buy_now_plan_id,activation_email,activation_account_name,source} = to.query;
    if(buy_now_plan_id && activation_email) toAppend += `&activation_email=${activation_email}`;
    if(buy_now_plan_id && activation_account_name) toAppend += `&activation_account_name=${activation_account_name}`;
    if(buy_now_plan_id && source) toAppend += `&source=${source}`;

    if (to.query.fpr) {
      toAppend = toAppend + '&fpr=' + to.query.fpr;
    }
  }

  // stripe successfull redirect with "?upgradeSuccessful=true", navigate to home
  if (to.query.upgradeSuccessful === 'true' && to.name !== 'subscription_plans') {
    // add the query param upgradeSuccessful and from sunscription page keeep watch:{} on url if upgradeSuccessful=true navigate to home
    // it will get the render time to updatet the plan id 16 to 20 after stripe redirect
    console.log('Upgrade successful!',{to,currentUser});
    return next(frontendURL(`accounts/${currentUser.account_id}/settings/subscription-plans?upgradeSuccessful=true`));
    // window.location = `${DEFAULT_REDIRECT_URL}accounts/${user.account_id}/dashboard`;
  }

  if (!to.name) {
    const user = auth.getCurrentUser();
    if (user) {
      if (toAppend !== '') {
        return next(
          frontendURL(
            `accounts/${user.account_id}/settings/subscription-plans` + toAppend
          )
        );
      }
      return next(frontendURL(`accounts/${user.account_id}/dashboard`));
    }
    if (toAppend !== '') {
      return next('/app/auth/signup' + toAppend);
    }
    return next('/app/login' + toAppend);
  }

  // if first time signup, before subsctiption go to select and arrange business type
  if (to.name) {
    if (to.name === "arrange_business_type") {
      // return from here so not check rest condition
      return validateRouteAccess(to, from, next);
    }else{
      const user = auth.getCurrentUser();
      const signup_flow = SIGNUP_FLOW.getFlow()
      console.log(to,user,signup_flow);
      if (signup_flow?.isNewSignup && signup_flow?.current_step === SIGNUP_FLOW.FLOWS.business_type && user?.plan_id === 16) {
        // strict user on this page
        return next(frontendURL(`accounts/${user.account_id}/arrange/business-type`,to.query));
      }
    }

  }

  // lock the user if plan_name=free, plan_id=16  user at subscription page
  if (to.name && to.name !== 'subscription_plans') {
    const user = auth.getCurrentUser();
    // console.log(to);
    if (user && user.plan_id === 16) {
      // console.log("LOCK Free user path",user,user.plan_id);
      return next(frontendURL(`accounts/${user.account_id}/settings/subscription-plans`,to.query));
    }
  }

  // redirect the user to home if user.type !== "SuperAdmin" for credit routes
  if (to.path.includes('/credit/')) {
    const user = auth.getCurrentUser();
    if (user.type !== "SuperAdmin") {
      return next(frontendURL(`accounts/${user.account_id}/dashboard`));
    }
  }

  return validateRouteAccess(to, from, next);
});

export default router;
