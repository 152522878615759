<template>
  <div class="settings columns container">
    <setting-intro-banner :header-image="inbox.avatarUrl" :header-title="inboxName">
      <woot-tabs :index="selectedTabIndex" @change="onTabChange">
        <woot-tabs-item v-for="tab in tabs" :key="tab.key" :name="tab.name" :show-badge="false" />
      </woot-tabs>
    </setting-intro-banner>

    <div v-if="selectedTabKey === 'inbox_settings'" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_SUB_TEXT')"
      >
        <woot-avatar-uploader
          :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL')"
          :src="avatarUrl"
          class="settings-item"
          delete-avatar
          @change="handleImageUpload"
          @onAvatarDelete="handleAvatarDelete"
        />
        <woot-input
          v-model.trim="selectedInboxName"
          class="medium-9 columns settings-item"
          :label="inboxNameLabel"
          :placeholder="inboxNamePlaceHolder"
        />
        <label v-if="isATwitterInbox" for="toggle-business-hours" class="toggle-input-wrap">
          <input v-model="tweetsEnabled" type="checkbox" name="toggle-business-hours" />
          {{ $t('INBOX_MGMT.ADD.TWITTER.TWEETS.ENABLE') }}
        </label>
        <woot-input
          v-if="isAPIInbox"
          v-model.trim="webhookUrl"
          class="medium-9 columns settings-item"
          :class="{ error: $v.webhookUrl.$error }"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.LABEL')
          "
          :placeholder="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.PLACEHOLDER')
          "
          :error="
            $v.webhookUrl.$error
              ? $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.ERROR')
              : ''
          "
          @blur="$v.webhookUrl.$touch"
        />
        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWebsiteUrl"
          class="medium-9 columns settings-item"
          :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.PLACEHOLDER')
          "
        />
        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWelcomeTitle"
          class="medium-9 columns settings-item"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.LABEL')
          "
          :placeholder="
            $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.PLACEHOLDER'
            )
          "
        />

        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWelcomeTagline"
          class="medium-9 columns settings-item"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL')
          "
          :placeholder="
            $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
            )
          "
        />

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_COLOR.LABEL') }}
          <woot-color-picker v-model="inbox.widget_color" />
        </label>

        <label class="medium-9 columns settings-item">
          {{
          $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.LABEL')
          }}
          <select
            v-model="greetingEnabled"
          >
            <option :value="true">
              {{
              $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.ENABLED'
              )
              }}
            </option>
            <option :value="false">
              {{
              $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.DISABLED'
              )
              }}
            </option>
          </select>
          <p class="help-text">
            {{
            $t(
            'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.HELP_TEXT'
            )
            }}
          </p>
        </label>
        <div v-if="greetingEnabled" class="settings-item">
          <greetings-editor
            v-model.trim="greetingMessage"
            :label="
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL'
              )
            "
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.PLACEHOLDER'
              )
            "
            :richtext="!textAreaChannels"
          />
        </div>
        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.TITLE') }}
          <select v-model="replyTime">
            <option key="in_a_few_minutes" value="in_a_few_minutes">
              {{
              $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_MINUTES')
              }}
            </option>
            <option key="in_a_few_hours" value="in_a_few_hours">
              {{
              $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_HOURS')
              }}
            </option>
            <option
              key="in_a_day"
              value="in_a_day"
            >{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_DAY') }}</option>
          </select>

          <p class="help-text">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.HELP_TEXT') }}</p>
        </label>

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX') }}
          <select
            v-model="emailCollectEnabled"
          >
            <option :value="true">{{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.ENABLED') }}</option>
            <option :value="false">{{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.DISABLED') }}</option>
          </select>
          <p class="help-text">
            {{
            $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX_SUB_TEXT')
            }}
          </p>
        </label>

        <label class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT') }}
          <select v-model="autoAssignment">
            <option :value="true">{{ $t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.ENABLED') }}</option>
            <option :value="false">{{ $t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.DISABLED') }}</option>
          </select>
          <p class="help-text">{{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT_SUB_TEXT') }}</p>
        </label>

        <label class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT') }}
          <select v-model="csatSurveyEnabled">
            <option :value="true">{{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.ENABLED') }}</option>
            <option :value="false">{{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.DISABLED') }}</option>
          </select>
          <p class="help-text">{{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT_SUB_TEXT') }}</p>
        </label>

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED') }}
          <select
            v-model="allowMessagesAfterResolved"
          >
            <option :value="true">{{ $t('INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.ENABLED') }}</option>
            <option
              :value="false"
            >{{ $t('INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.DISABLED') }}</option>
          </select>
          <p class="help-text">
            {{
            $t(
            'INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED_SUB_TEXT'
            )
            }}
          </p>
        </label>

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL') }}
          <select
            v-model="continuityViaEmail"
          >
            <option :value="true">{{ $t('INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.ENABLED') }}</option>
            <option :value="false">{{ $t('INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.DISABLED') }}</option>
          </select>
          <p class="help-text">
            {{
            $t(
            'INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL_SUB_TEXT'
            )
            }}
          </p>
        </label>

        <label v-if="isAWebWidgetInbox">{{ $t('INBOX_MGMT.FEATURES.LABEL') }}</label>
        <div v-if="isAWebWidgetInbox" class="widget--feature-flag settings-item">
          <input
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="attachments"
            @input="handleFeatureFlag"
          />
          <label for="attachments">{{ $t('INBOX_MGMT.FEATURES.DISPLAY_FILE_PICKER') }}</label>
        </div>
        <div v-if="isAWebWidgetInbox" class="settings-item settings-item">
          <input
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="emoji_picker"
            @input="handleFeatureFlag"
          />
          <label for="emoji_picker">{{ $t('INBOX_MGMT.FEATURES.DISPLAY_EMOJI_PICKER') }}</label>
        </div>

        <woot-submit-button
          v-if="isAPIInbox"
          type="submit"
          :disabled="$v.webhookUrl.$invalid"
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="uiFlags.isUpdatingInbox"
          @click="updateInbox"
        />
        <woot-submit-button
          v-else
          type="submit"
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="uiFlags.isUpdatingInbox"
          @click="updateInbox"
        />
        <div v-if="isVonageInbox" style="display: inline-block;">
          <woot-submit-button
            v-if="register_whatsapp_api_url"
            type="button"
            :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.REGISTER_ACTIVE.BTNS.REGISTER')"
            :loading="false"
            @click="whatsAppRegPopupHandler"
          />
          <woot-submit-button
            v-if="isAllowedToActiveWaba"
            type="button"
            :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.REGISTER_ACTIVE.BTNS.ACTIVE')"
            :loading="isActiveWabaLoading"
            @click="activeWabaFinalizeHandler"
          />
          
        </div>
      </settings-section>
      <facebook-reauthorize v-if="isAFacebookInbox" :inbox-id="inbox.id" />
    </div>

    <!-- update agents in inbox -->

    <div v-if="selectedTabKey === 'collaborators'" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT')"
      >
        <multiselect
          v-model="selectedAgents"
          :options="agentList"
          track-by="id"
          label="name"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :hide-selected="true"
          :placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER')"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          @select="$v.selectedAgents.$touch"
        />

        <woot-submit-button
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="isAgentListUpdating"
          @click="updateAgents"
        />
      </settings-section>
    </div>

    <div v-if="selectedTabKey === 'catalogues'" class="settings--content">
      <div class="row catalouges-section">
        <div class="columns medium-2">
          <h6 class="catalouges-header">{{ $t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORIES') }}</h6>
        </div>
        <div class="columns medium-10">
          <multiselect
            v-model="selectedCategories"
            :options="categories"
            track-by="id"
            label="title"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            @tag="createCatalogueItems"
            :tag-placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES')"
            :taggable="true"
            @remove="deleteCatalogueSet"
            :placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          />
        </div>
        <div class="columns medium-2">
          <h6 class="catalouges-header">{{ $t('INBOX_MGMT.SETTINGS_POPUP.CATALOUGUES_SUB_TEXT') }}</h6>
        </div>
        <div class="columns medium-10">
          <div class="row item-header">
            <woot-button
              @click="refreshCatalogueItems"
            >{{ $t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.REFRESH') }}</woot-button>
            <woot-button
              @click="updateCatalogueItems"
              style="margin-left: 10px;"
            >{{ $t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.UPDATE') }}</woot-button>
          </div>
          <div class="row">
            <div class="item-wrap column medium-4" v-for="item in catalogueItems" :key="item.id">
              <div class="image-wrap">
                <img :src="item.image_link" :alt="item.title" />
              </div>
              <h6>{{ item.title }}</h6>
              <p>{{ item.description }}</p>
              <span>{{ $t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.PRICE') }}: {{ item.price }},</span>
              <span>{{ $t('CONVERSATION.QUANTITY') }}: {{ item.quantity_to_sell_on_fb }}</span>
              <div class="row" style="margin-top: 10px;">
                <label
                  class="column small-2"
                >{{ $t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY') }}</label>
                <multiselect
                  class="column small-10"
                  v-model="item.categories"
                  :options="categories"
                  track-by="id"
                  label="title"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :hide-selected="true"
                  placeholder="Pick categories"
                  selected-label
                  :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                  :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedTabKey === 'configuration'">
      <div v-if="isATwilioChannel" class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.TITLE')"
          :sub-title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE')"
        >
          <woot-code :script="inbox.callback_webhook_url" lang="html"></woot-code>
        </settings-section>
      </div>
      <div v-else-if="isALineChannel" class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.TITLE')"
          :sub-title="$t('INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.SUBTITLE')"
        >
          <woot-code :script="inbox.callback_webhook_url" lang="html"></woot-code>
        </settings-section>
      </div>
      <div v-else-if="isAWebWidgetInbox">
        <div class="settings--content">
          <settings-section
            :title="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_HEADING')"
            :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_SUB_HEAD')"
          >
            <woot-code :script="inbox.web_widget_script"></woot-code>
          </settings-section>

          <settings-section
            :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION')"
            :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION')"
          >
            <woot-code :script="inbox.hmac_token"></woot-code>
          </settings-section>
          <settings-section
            :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_VERIFICATION')"
            :sub-title="
              $t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_DESCRIPTION')
            "
          >
            <div class="enter-to-send--checkbox">
              <input
                id="hmacMandatory"
                v-model="hmacMandatory"
                type="checkbox"
                @change="handleHmacFlag"
              />
              <label for="hmacMandatory">{{ $t('INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL') }}</label>
            </div>
          </settings-section>
        </div>
      </div>
      <div v-else-if="isAPIInbox" class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER_SUB_TEXT')"
        >
          <woot-code :script="inbox.inbox_identifier"></woot-code>
        </settings-section>
      </div>
      <div v-else-if="isAnEmailChannel">
        <div class="settings--content">
          <settings-section
            :title="$t('INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_TITLE')"
            :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_SUB_TEXT')"
          >
            <woot-code :script="inbox.forward_to_email"></woot-code>
          </settings-section>
        </div>
        <imap-settings :inbox="inbox" />
        <smtp-settings :inbox="inbox" />
      </div>
    </div>
    <div v-if="selectedTabKey === 'preChatForm'">
      <pre-chat-form-settings :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'businesshours'">
      <weekly-availability :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'connect_phone'" class="settings--content">
      <settings-section
          title="Connect Phone"
          sub-title="Connect your phone number with WABA inbox"
        >
          <verify-waba-code :isRedirectToAddAgent="false" :isHardReloadOnSuccess="true" />
        </settings-section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { createMessengerScript } from 'dashboard/helper/scriptGenerator';
import { required } from 'vuelidate/lib/validators';
import { shouldBeUrl } from 'shared/helpers/Validators';
import configMixin from 'shared/mixins/configMixin';
import alertMixin from 'shared/mixins/alertMixin';
import SettingIntroBanner from 'dashboard/components/widgets/SettingIntroBanner';
import SettingsSection from '../../../../components/SettingsSection';
import inboxMixin from 'shared/mixins/inboxMixin';
import FacebookReauthorize from './facebook/Reauthorize';
import PreChatFormSettings from './PreChatForm/Settings';
import WeeklyAvailability from './components/WeeklyAvailability';
import GreetingsEditor from 'shared/components/GreetingsEditor';
import ImapSettings from './ImapSettings';
import SmtpSettings from './SmtpSettings';
import CatalogueAPI from '../../../../api/inbox/catalogue';
import GrantAPI from '../../../../api/grants';
import {attributeHelpers} from "../../../../helper/demoAutomationHelper"
import VerifyWabaCode from './VerifyWabaCode.vue';
import WebChannelAPI from '../../../../api/channel/webChannel';

export default {
  components: {
    SettingIntroBanner,
    SettingsSection,
    FacebookReauthorize,
    PreChatFormSettings,
    WeeklyAvailability,
    GreetingsEditor,
    ImapSettings,
    SmtpSettings,
    VerifyWabaCode,
  },
  mixins: [alertMixin, configMixin, inboxMixin],
  data() {
    return {
      avatarFile: null,
      avatarUrl: '',
      selectedAgents: [],
      greetingEnabled: true,
      tweetsEnabled: true,
      hmacMandatory: null,
      greetingMessage: '',
      autoAssignment: false,
      emailCollectEnabled: false,
      isAgentListUpdating: false,
      csatSurveyEnabled: false,
      allowMessagesAfterResolved: true,
      continuityViaEmail: true,
      selectedInboxName: '',
      channelWebsiteUrl: '',
      webhookUrl: '',
      channelWelcomeTitle: '',
      channelWelcomeTagline: '',
      selectedFeatureFlags: [],
      replyTime: '',
      autoAssignmentOptions: [
        {
          value: true,
          label: this.$t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.ENABLED'),
        },
        {
          value: false,
          label: this.$t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.DISABLED'),
        },
      ],
      selectedTabIndex: 0,
      catalogueItems: [],
      catalogueObj: null,
      categories: [],
      selectedCategories: [],
      inbox_provider_type: null,
      register_whatsapp_api_url: null,
      isAllowedToActiveWaba: false,
      isActiveWabaLoading: false,
      grantInboxPhoneConnectStatus: null,  // 0,1,2
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      agentList: 'agents/getAgents',
      uiFlags: 'inboxes/getUIFlags',
    }),
    selectedTabKey() {
      return this.tabs[this.selectedTabIndex]?.key;
    },
    tabs() {
      const visibleToAllChannelTabs = [
        {
          key: 'inbox_settings',
          name: this.$t('INBOX_MGMT.TABS.SETTINGS'),
        },
        {
          key: 'collaborators',
          name: this.$t('INBOX_MGMT.TABS.COLLABORATORS'),
        },
        {
          key: 'businesshours',
          name: this.$t('INBOX_MGMT.TABS.BUSINESS_HOURS'),
        },      
      ];

      if (
        !Array.isArray(this.catalogueObj) &&
        this.catalogueObj &&
        Object.keys(this.catalogueItems).length > 0
      ) {
        return [
          ...visibleToAllChannelTabs,
          {
            key: 'catalogues',
            name: this.$t('INBOX_MGMT.TABS.CATALOGUES'),
          },
        ];
      }

      if (
        !Array.isArray(this.catalogueObj) &&
        this.catalogueObj &&
        Object.keys(this.catalogueItems).length > 0
      ) {
        console.log('here computed log');
        return [
          ...visibleToAllChannelTabs,
          {
            key: 'catalogues',
            name: this.$t('INBOX_MGMT.TABS.CATALOGUES'),
          },
        ];
      }

      if (this.isAWebWidgetInbox) {
        return [
          ...visibleToAllChannelTabs,
          {
            key: 'preChatForm',
            name: this.$t('INBOX_MGMT.TABS.PRE_CHAT_FORM'),
          },
          {
            key: 'configuration',
            name: this.$t('INBOX_MGMT.TABS.CONFIGURATION'),
          },
        ];
      }

      if (
        this.isATwilioChannel ||
        this.isALineChannel ||
        this.isAPIInbox ||
        this.isAnEmailChannel
      ) {
        return [
          ...visibleToAllChannelTabs,
          {
            key: 'configuration',
            name: this.$t('INBOX_MGMT.TABS.CONFIGURATION'),
          },
        ];
      }

      // add WABA phone_connect verify tab, if it is a grantInbox, and status==1
      const isGrantInbox = this.inbox.provider_type === "Vonage";
      if (isGrantInbox && this.grantInboxPhoneConnectStatus === 1) {
        visibleToAllChannelTabs.unshift({
          key: 'connect_phone',
          name: "Connect Phone",
        })
      }

      return visibleToAllChannelTabs;
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    inboxName() {
      if (this.isATwilioSMSChannel || this.isATwilioWhatsappChannel) {
        return `${this.inbox.name} (${this.inbox.phone_number})`;
      }
      if (this.isAnEmailChannel) {
        return `${this.inbox.name} (${this.inbox.email})`;
      }
      return this.inbox.name;
    },
    messengerScript() {
      return createMessengerScript(this.inbox.page_id);
    },
    inboxNameLabel() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.LABEL');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.LABEL');
    },
    inboxNamePlaceHolder() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.PLACEHOLDER');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.PLACEHOLDER');
    },
    textAreaChannels() {
      if (
        this.isATwilioChannel ||
        this.isATwitterInbox ||
        this.isAFacebookInbox
      )
        return true;
      return false;
    },
    isVonageInbox(){
      console.log({
        prodiverType: this.inbox_provider_type,
        isrestun: this.inbox_provider_type?.trim()?.toLowerCase() === 'vonage',
      });
      const isVonageInbox= this.inbox_provider_type?.trim()?.toLowerCase() === 'vonage';
      return isVonageInbox &&  this.isAllowedToActiveWaba;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'settings_inbox_show') {
        this.fetchInboxSettings();
        this.getCatalogue();
        this.getProductSets();
      }
    },
    inbox: {
      handler(newInbox, oldInbox) {
        if (newInbox) {
          this.checkGrantPhoneConnectStatus();
        }
      },
      immediate: true 
    },
  },
  mounted() {
    this.fetchInboxSettings();
    this.getCatalogue();
    this.getProductSets();
    this.getWhatsAppRegAPIurl();
    this.getWabaFinalizeUrl();
  },
  methods: {
    getProductSets() {
      CatalogueAPI.getProductSets(this.currentInboxId).then(response => {
        this.categories = response.data;
        this.selectedCategories = response.data;
      });
    },
    getCatalogue() {
      CatalogueAPI.getCatalogue(this.currentInboxId).then(response => {
        this.catalogueObj = response.data;
        if (response.data) {
          this.getCatalogueItems();
        }
      });
    },
    getCatalogueItems() {
      CatalogueAPI.get(this.currentInboxId).then(response => {
        this.catalogueItems = response.data;
      });
    },
    refreshCatalogueItems() {
      let catalogueId = this.catalogueItems[0].catalog_id;
      let dataToSend = {
        inbox_id: this.currentInboxId,
        id: catalogueId,
      };
      CatalogueAPI.refreshCatalogue(dataToSend);
    },
    deleteCatalogueSet(selectedTag) {
      let dataToSend = {
        inbox_id: this.currentInboxId,
        id: selectedTag.id,
      };
      CatalogueAPI.deleteCategory(dataToSend).then(response => {
        this.getProductSets();
      });
    },
    createCatalogueItems(newTag) {
      let dataToSend = {
        inbox_id: this.currentInboxId,
        title: newTag,
      };
      CatalogueAPI.createCategory(dataToSend).then(response => {
        this.getProductSets();
      });
    },
    updateCatalogueItems() {
      let data = [];
      for (let i = 0; i < this.catalogueItems.length; i++) {
        let item = this.catalogueItems[i];
        let categoryIds = item.categories.map(category => category.id);
        for (let j = 0; j < categoryIds.length; j++) {
          let categoryId = categoryIds[j];
          let categoryEntry = data.find(entry => entry.id === categoryId);
          if (categoryEntry) {
            categoryEntry.items.push(item.id);
          } else {
            data.push({ id: categoryId, items: [item.id] });
          }
        }
      }
      let dataToSend = {
        inbox_id: this.currentInboxId,
        categories: data,
      };
      CatalogueAPI.updateCategories(dataToSend).then(response => {
        this.getCatalogueItems();
      });
    },
    handleFeatureFlag(e) {
      this.selectedFeatureFlags = this.toggleInput(
        this.selectedFeatureFlags,
        e.target.value
      );
    },
    handleHmacFlag() {
      this.updateInbox();
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        const newSelectedFlags = selected.filter(flag => flag !== current);
        return newSelectedFlags;
      }
      return [...selected, current];
    },
    onTabChange(selectedTabIndex) {
      this.selectedTabIndex = selectedTabIndex;
    },
    fetchInboxSettings() {
      this.selectedTabIndex = 0;
      this.selectedAgents = [];
      this.$store.dispatch('agents/get');
      this.$store.dispatch('teams/get');
      this.$store.dispatch('labels/get');
      this.$store.dispatch('inboxes/get').then(() => {
        this.fetchAttachedAgents();
        this.avatarUrl = this.inbox.avatar_url;
        this.selectedInboxName = this.inbox.name;
        this.webhookUrl = this.inbox.webhook_url;
        this.greetingEnabled = this.inbox.greeting_enabled || false;
        this.tweetsEnabled = this.inbox.tweets_enabled || false;
        this.hmacMandatory = this.inbox.hmac_mandatory || false;
        this.greetingMessage = this.inbox.greeting_message || '';
        this.autoAssignment = this.inbox.enable_auto_assignment;
        this.emailCollectEnabled = this.inbox.enable_email_collect;
        this.csatSurveyEnabled = this.inbox.csat_survey_enabled;
        this.allowMessagesAfterResolved = this.inbox.allow_messages_after_resolved;
        this.continuityViaEmail = this.inbox.continuity_via_email;
        this.channelWebsiteUrl = this.inbox.website_url;
        this.channelWelcomeTitle = this.inbox.welcome_title;
        this.channelWelcomeTagline = this.inbox.welcome_tagline;
        this.selectedFeatureFlags = this.inbox.selected_feature_flags || [];
        this.replyTime = this.inbox.reply_time;
        this.inbox_provider_type = this.inbox.provider_type;

        console.log({inbox_sting: this.inbox});
      });
    },
    async fetchAttachedAgents() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.currentInboxId,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.selectedAgents = inboxMembers;
      } catch (error) {
        //  Handle error
      }
    },
    async updateAgents() {
      const agentList = this.selectedAgents.map(el => el.id);
      this.isAgentListUpdating = true;
      try {
        await this.$store.dispatch('inboxMembers/create', {
          inboxId: this.currentInboxId,
          agentList,
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
      this.isAgentListUpdating = false;
    },
    async updateInbox() {
      try {
        const payload = {
          id: this.currentInboxId,
          name: this.selectedInboxName,
          enable_auto_assignment: this.autoAssignment,
          enable_email_collect: this.emailCollectEnabled,
          csat_survey_enabled: this.csatSurveyEnabled,
          allow_messages_after_resolved: this.allowMessagesAfterResolved,
          greeting_enabled: this.greetingEnabled,
          greeting_message: this.greetingMessage || '',
          channel: {
            widget_color: this.inbox.widget_color,
            website_url: this.channelWebsiteUrl,
            webhook_url: this.webhookUrl,
            welcome_title: this.channelWelcomeTitle || '',
            welcome_tagline: this.channelWelcomeTagline || '',
            selectedFeatureFlags: this.selectedFeatureFlags,
            reply_time: this.replyTime || 'in_a_few_minutes',
            hmac_mandatory: this.hmacMandatory,
            tweets_enabled: this.tweetsEnabled,
            continuity_via_email: this.continuityViaEmail,
          },
        };
        if (this.avatarFile) {
          payload.avatar = this.avatarFile;
        }
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      }
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async handleAvatarDelete() {
      try {
        await this.$store.dispatch(
          'inboxes/deleteInboxAvatar',
          this.currentInboxId
        );
        this.avatarFile = null;
        this.avatarUrl = '';
        this.showAlert(this.$t('INBOX_MGMT.DELETE.API.AVATAR_SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('INBOX_MGMT.DELETE.API.AVATAR_ERROR_MESSAGE')
        );
      }
    },
    // get META register API
    async getWhatsAppRegAPIurl(){
      try {
        const {data:responseGrant} = await GrantAPI.checkMetaUrl(this.currentInboxId);
        
        if (responseGrant.status === 200) {
          this.register_whatsapp_api_url = responseGrant.link; 
        }else{
          this.register_whatsapp_api_url = null; 
        }
      } catch (error) {
        console.log(error);
        this.register_whatsapp_api_url = null;
      }
    },

    whatsAppRegPopupHandler(){
      if (this.register_whatsapp_api_url) {
        const popupFeature = 'width=500,height=600';
        window.open(this.register_whatsapp_api_url,"whatsapp register",popupFeature);
      }
    },

    // check to show to active WABA
    async getWabaFinalizeUrl(){
      
      try {
        const {data:responseWABA} = await GrantAPI.checkWabaFinalization(this.currentInboxId);
        if (responseWABA.connected) {
          this.isAllowedToActiveWaba = false;
        }else{
          this.isAllowedToActiveWaba = true;
        }
      } catch (error) {
        console.log(error);
        this.isAllowedToActiveWaba = false;
      }
    },
    // active WABA
    async activeWabaFinalizeHandler(){
      try {
        this.isActiveWabaLoading = true;
        if (this.isAllowedToActiveWaba && this.currentInboxId) {
          const {data:activeWabaResponse} = await GrantAPI.activeWabaFinalize(this.currentInboxId);
          console.log({activeWabaResponse});
          if (activeWabaResponse.status === 200) {
            this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.REGISTER_ACTIVE.ACTIVE_SUCCESS_MSG'));
            // Start: update onboar_class for grant user
            const {peasy_sale} = attributeHelpers.onboardClassValues;
            await attributeHelpers.update_onboard_class_value(this.currentUser,peasy_sale.embadedSignUpOrActivatedWABA)
            // End: update onboar_class for grant user

            this.$router.push({name:"settings_inbox"})
          }
        }else{
          this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.REGISTER_ACTIVE.ERR_MSG'));
        }
        this.isActiveWabaLoading = false;
      } catch (error) {
        console.log(error);
        this.isActiveWabaLoading = false;
        this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.REGISTER_ACTIVE.ERR_MSG'));

      }
    },

    // check phone_connect status for grant inbox
    async checkGrantPhoneConnectStatus (){
      try {
        const isGrantInbox = this.inbox.provider_type === "Vonage";
        if (!isGrantInbox) {
          return;
        }
        const {data:wabaStatusData} = await WebChannelAPI.getWabaInboxStatus(this.currentInboxId);
        this.grantInboxPhoneConnectStatus = wabaStatusData.status;
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.MSG.HTTP_ERR'));
      }
    },

    

  },
  validations: {
    webhookUrl: {
      required,
      shouldBeUrl,
    },
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.settings {
  background: $color-white;

  .settings--content {
    div:last-child {
      border-bottom: 0;
    }
  }

  .tabs {
    padding: 0;
    margin-bottom: -1px;
  }
}

.widget--feature-flag {
  padding-top: var(--space-small);
}

.settings-item {
  padding-bottom: var(--space-normal);

  .help-text {
    font-style: normal;
    color: var(--b-500);
    padding-bottom: var(--space-smaller);
  }
}
.catalouges-section {
  padding-top: 20px;
  .catalouges-header {
    color: $color-woot;
  }
  .item-header {
    justify-content: end;
  }
  .item-wrap {
    padding: 20px;
    .image-wrap {
      height: 350px;
      width: 100%;
      margin-bottom: 10px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.modal_body{
  padding: var(--space-normal) var(--space-large) var(--space-large);
}
</style>
