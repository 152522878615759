<template>
  <div class="column padding-top">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="arrow-download"
      :disabled="!usagesOrderReport"
      @click="downloadReport"
    >
      {{ $t('FLOW.DOWNLOAD_REPORT') }}
    </woot-button>

    <div class="row">
      <div class="report-bar" style="width:100%;">
        <div class="row">
          <div class="column">
            <h4>Usages of Order</h4>
            <OrderUsageTable
              :is-loading="isFetching"
              :usagesOrderReport="usagesOrderReport"
            ></OrderUsageTable>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import OrderUsageTable from "./OrderUsageTable.vue"

export default {
  name:"UsagesOfOrder",
  components:{
    OrderUsageTable,
  },
  data(){
    return {}
  },
  computed:{
    ...mapGetters({
      currentUser: 'getCurrentUser',
      isFetching: 'getIsFetchingUsageReport',
      usagesOrderReport: "getUsagesOrderReport",
    })
  },
  mounted(){
    this.$store.dispatch('fetchUsagesOrderReport')
  },
  methods:{
    // downloadReport(){
    //   console.log("write the report download logic");
    // },
    downloadReport() {
      const dataArray = this.usagesOrderReport;
      const filename = 'usages_of_order.csv'

      if (!dataArray || !dataArray.length) {
        console.error("No data available to download.");
        return;
      }

      // Helper function to handle null or undefined values
      function replacer(key, value) {
        return value === null || value === undefined ? '' : value;
      }

      // Extract keys (column headers) from the first object
      const headers = Object.keys(dataArray[0]);

      // Convert array of objects to CSV
      const csvData = [
        headers.join(','),
        ...dataArray.map(row =>
          headers.map(field => JSON.stringify(row[field], replacer)).join(',')
        )
      ].join('\r\n'); // Separate each row with new line


      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });


      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }

    // end of methods
  },
}
</script>




<style lang="scss" scoped>
.padding-top {
  padding-top: 1.6rem;
}
.report-bar {
  padding: 1.6rem;
}
.csat--metrics-container {
  background: var(--white);
  margin-bottom: var(--space-two);
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  padding: var(--space-normal);
}
</style>
