<template>
  <section class="orders-table-wrap">
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 11.4rem)"
      scroll-width="187rem"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />

    <!--
    <empty-state
      v-if="showSearchEmptyState"
      :title="$t('CONTACTS_PAGE.LIST.404')"
    />
    <empty-state
      v-else-if="!isLoading && !contacts.length"
      :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS')"
    />
    <div v-if="isLoading" class="orders--loader">
      <spinner />
      <span>{{ $t('CONTACTS_PAGE.LIST.LOADING_MESSAGE') }}</span>
    </div>
    -->

  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import { mapGetters } from 'vuex';
import ContactApi from '../../../../api/contacts'
import CatalogAPI from "../../../../api/catalogue/catalog";
import alertMixin from 'shared/mixins/alertMixin';


export default {
    components:{
        VeTable,
        Spinner,
    },
    mixins: [alertMixin],
    props:{
        orders:{
            type: Array,
            default: () => []
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        cols: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        catalog: {
            type: Object,
            default: {},
        },
        onClickOrder: {
            type: Function,
            default: () => {},
        },
        onSelectOrderStatus: {
            type: Function,
            default: () => {},
        },

    },
    data(){
        return {
          sortConfig: {},
          sortOption: {
            sortAlways: true,
            sortChange: params => this.$emit('on-sort-change', params),
          },
        }
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
        }),
        tableData(){
            // console.log(this.cols,this.orders);
            if (this.isLoading) {
                return [];
            }

            const formattedOrders = this.orders.map(order=>{
                const preSet = {
                    ...order,
                    order_id: order.id,
                    order_status: order.order_status,
                    created_at: order.created_at,
                    line1: order?.shipment_address?.line1,
                    line2: order?.shipment_address?.line2,
                    city: order?.shipment_address?.city,
                    state: order?.shipment_address?.state,
                    postalCode: order?.shipment_address?.postalCode,
                    country: order?.shipment_address?.country,
                }
                return preSet;
            })

            return formattedOrders
        },
        columns(){
            let matches = {};

            matches.order_id = {
                field: 'order_id',
                key: 'order_id',
                title: 'Order ID',
                fixed: 'left',
                align: 'left',
                width: 200,
                sortBy: this.sortConfig.order_id || '',
                renderBodyCell: ({row})=>{
                    // return <span>{row.order_id}</span>
                    return (
                      <woot-button
                          variant="clear"
                          onClick={() => this.onClickOrder(row)}
                      >
                      {row.order_id}
                      </woot-button>
                    )
                }
            }

            matches.order_status = {
                field: 'order_status',
                key: 'order_status',
                title: 'Order Status',
                align: 'center',
                width: 200,
                sortBy: this.sortConfig.order_status || '',
                renderBodyCell: ({row})=>{
                    const options = [
                        { value: 'fresh', label: 'Fresh', status_code: 0 },
                        { value: 'pending', label: 'Pending', status_code: 1 },
                        { value: 'cancelled', label: 'Cancelled', status_code: 2 },
                        { value: 'packed', label: 'Packed', status_code: 3 },
                        { value: 'out_for_delivery', label: 'Out for Delivery', status_code: 4 },
                        { value: 'refunded', label: 'Refunded', status_code: 5 },
                        { value: 'completed', label: 'Completed', status_code: 6 },
                        { value: 'paid', label: 'Paid', status_code: 7 }
                    ];

                     const handleSelectChange = (e) => {
                        const selectedOption = options.find(option => option.value === e.target.value);
                         this.onSelectOrderStatus(row, selectedOption.status_code);
                    };

                    return (
                        <div class="status_wrapper">
                            <select
                                v-model={row.order_status}
                                onChange={handleSelectChange}
                                value={row.order_status}
                            >{
                                options.map(option=> <option value={option.value}>{option.label}</option>)
                            }
                            </select>
                        </div>
                    )

                }
            }


            matches.created_at = {
                field: 'created_at',
                key: 'created_at',
                title: 'Created At',
                width: 240,
                sortBy: this.sortConfig.created_at || '',
                renderBodyCell: ({row})=>{
                    return (<span>{new Date(row.created_at).toLocaleString()}</span>)
                }
            }
            matches.order_total = {
                field: 'order_total',
                key: 'order_total',
                title: 'Order Total',
                width: 240,
                sortBy: this.sortConfig.order_total || '',
                renderBodyCell: ({row})=>{
                    return (<span>{row.order_total}</span>)
                }
            }
            matches.grand_total = {
                field: 'grand_total',
                key: 'grand_total',
                title: 'Grand Total',
                width: 240,
                sortBy: this.sortConfig.grand_total || '',
                renderBodyCell: ({row})=>{
                    return (<span>{row.grand_total}</span>)
                }
            }
            matches.refund_amount = {
                field: 'refund_amount',
                key: 'refund_amount',
                title: 'Refunded Amount',
                width: 240,
                sortBy: this.sortConfig.refund_amount || '',
                renderBodyCell: ({row})=>{
                    return (<span>{row.refund_amount}</span>)
                }
            }
            matches.paid_total = {
                field: 'paid_total',
                key: 'paid_total',
                title: 'Paid Total',
                width: 240,
                sortBy: this.sortConfig.paid_total || '',
                renderBodyCell: ({row})=>{
                    return (<span>{row.paid_total}</span>)
                }
            }

            matches.name = {
                field: 'name',
                key: 'name',
                title: 'Name',
                sortBy: this.sortConfig.name || '',
                width: 240,
                align:"left",
                renderBodyCell: ({row})=>{
                    return (<span>
                        {row.contact?.name}
                        {
                            // row.conversation_id
                            // ?
                            <woot-button
                                size="small"
                                variant="clear"
                                color-scheme="primary"
                                icon="chat"
                                onClick={()=>this.navigateChatHandler(row)}
                            >
                            </woot-button>
                            // : null
                        }

                    </span>)
                }
            }
            matches.phone_number = {
                field: 'phone_number',
                key: 'phone_number',
                title: 'Phone Number',
                sortBy: this.sortConfig.phone_number || '',
                width: 340,
                renderBodyCell: ({row})=>{
                    return (<span>{row.contact?.phone_number}</span>)
                }
            }
            matches.line1 = {
                field: 'line1',
                key: 'line1',
                title: 'Line1',
                sortBy: this.sortConfig.line1 || '',
                width: 240,
                renderBodyCell: ({row})=>{
                    return (<span>{row.line1}</span>)
                }
            }
            matches.line2 = {
                field: 'line2',
                key: 'line2',
                title: 'Line2',
                sortBy: this.sortConfig.line2 || '',
                width: 240,
                renderBodyCell: ({row})=>{
                    return (<span>{row.line2}</span>)
                }
            }
            matches.city = {
                field: 'city',
                key: 'city',
                title: 'City',
                sortBy: this.sortConfig.city || '',
                width: 240,
                renderBodyCell: ({row})=>{
                    return (<span>{row.city}</span>)
                }
            }
            matches.state = {
                field: 'state',
                key: 'state',
                title: 'State',
                sortBy: this.sortConfig.state || '',
                width: 240,
                renderBodyCell: ({row})=>{
                    return (<span>{row.state}</span>)
                }
            }
            matches.postalCode = {
                field: 'postalCode',
                key: 'postalCode',
                title: 'PostalCode',
                sortBy: this.sortConfig.postalCode || '',
                width: 240,
                renderBodyCell: ({row})=>{
                    return (<span>{row.postalCode}</span>)
                }
            }
            matches.country = {
                field: 'country',
                key: 'country',
                title: 'Country',
                sortBy: this.sortConfig.country || '',
                width: 240,
                renderBodyCell: ({row})=>{
                    return (<span>{row.country}</span>)
                }
            }


            const preSet = [];
            this.cols.forEach(col=>{
                if (col.visible) {
                    if (col.key in matches) {
                        preSet.push(matches[col.key])
                    }
                }
            })
            return preSet;
        },
        // end computed
    },
    methods:{
        async navigateChatHandler(order){
            // call api to get the conversation id, then go to that conversation page
            try {
                // const {data:catalog} = await CatalogAPI.getDefaultCatalog();
                const {data:conversation} = await ContactApi.getShopContactConversationUrl({
                    contact_id: order.contact?.id,     // order.contact?.id
                    inbox_id: this.catalog.inbox_id,
                })
                console.log({conversation,catalog:this.catalog});
                if (Array.isArray(conversation) && conversation?.length) {
                    this.$router.push(`/app/accounts/${this.currentUser.account_id}/conversations/${conversation[0]}`)

                }
                else{
                    this.showAlert("No contact found!");
                }
            } catch (error) {
                console.log(error);
            }
        },

        // End methods
    },
}
</script>



<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.orders-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.orders-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
  .status_wrapper select{
    padding-left: 8px;
  }
}

.orders--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}


</style>
