<template>
    <div v-if="isOpen" class="event_wrapper">
        <modal
            :show.sync="isOpen"
            :on-close="closeModal"
            closeBtnClass="event_close_btn"
            modalMainContainerClass=""
        >
            <div class="event">
                <a :href="contest.landing_page_url" target="_blank">
                    <img :src="contest.poster_url" alt="">
                </a>
                <a class="join_btn enent_btn_wrap" :href="contest.landing_page_url" target="_blank">
                    <woot-button class="event_join_btn">Join Now </woot-button>
                </a>
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from '../../../Modal.vue';
import endPoints from '../../../../api/endPoints';

export default {
    components:{
        Modal,
    },
    data(){
        return {
            isOpen: false,
            isContestOpen: false,
            contest: {
                poster_url: "",
                landing_page_url: ""
            },
        }
    },
    computed:{},
    mounted(){
        this.getRecentContest();
    },
    watch:{
        isOpen(newValue) {
            // Emit an event to inform the parent whenever `isOpen` changes
            this.$emit('update:isOpen', newValue);
        },
    },
    methods:{
        async getRecentContest() {
            try {
                // check if recent login()
                const isRecentLogin = localStorage.getItem('recentLoginContestEvent');
                console.log({isRecentLogin});
                if (isRecentLogin === "true") {
                    const { data: contest } = await axios.get(endPoints('contestEvent').url());
                    if (contest && contest.poster_url) {
                        this.contest = contest;
                        this.isOpen = true;
                    }
                }
            } catch (error) {
                console.error("Failed to fetch contest data:", error);
            }
        },
        closeModal(){
            localStorage.clear('recentLoginContestEvent')
            this.isOpen = false;
        },
    },

}
</script>

<style lang="scss" scoped>
// @import '~dashboard/assets/scss/variables';

.event{
    position: relative;
    .join_btn{
        position: absolute;
        bottom: 2%;
        left: 50%;
        transform: translateX(-50%);
    }

    .enent_btn_wrap{
        padding: 3px;
        background: #A320FB;
        border-radius: 10px;
        .event_join_btn{
            color: #A320FB;
            background: #CCFF3C;
            font-weight: 900;
            border-radius: 10px !important;
        }
    }
}

</style>

<style lang="scss">
// global style to insert css into clild component
.event_wrapper{
    .event_close_btn{
        z-index: 100;
        background: #fff;
        &:hover{
            background: var(--s-50);
        }
    }
}
</style>