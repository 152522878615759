
<template>
  <section class="conversation-page">
    <chat-list
      :conversation-inbox="inboxId"
      :label="label"
      :team-id="teamId"
      :conversation-type="conversationType"
      :folders-id="foldersId"
      @conversation-load="onConversationLoad"
    >
      <pop-over-search />
    </chat-list>
    <conversation-box
      :inbox-id="inboxId"
      :is-contact-panel-open="isContactPanelOpen"
      @contact-panel-toggle="onToggleContactPanel"
    >
    </conversation-box>

    <!-- show contest event modal first, then instruction popup  -->
    <ContestEventPopup @update:isOpen="onUpdateContestModalOpen" />

    <aside v-if="!isContestModalOpen">

      <!-- popuo with vide show start  -->
      <section class="instruction_popup modal-popup industry-popup modalWidth" v-if="isIndustryPoupOpen">
        <div class="wrapper_bg">
          <img :src="onboardingBgs.octopusAnimBg" alt="">
        </div>
          <div class="body_wrap">
            <div class="body primary_bg_shade">
              <h1 class="page-title banner_title">{{$t('INSTRUCTION_POPUP.ONBOARD_LIST.GREET')}}</h1>
              <div class="content_box">
                <h2 class="sub_title_font">{{$t('INSTRUCTION_POPUP.ONBOARD_LIST.PATH_HEADER')}}</h2>
                <p>{{$t('INSTRUCTION_POPUP.ONBOARD_LIST.INTRO')}}</p>
                <div class="card_video" :class="{ 'hidden': activeOption.videoUrl }">
                  <iframe
                    :src="activeOption.videoUrl"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                    ref="demoVideoFrame"
                  ></iframe>
                </div>
                <div class="cards_wrapper_industry">
                  <div class="industry_arrow industry_right_arrow">
                    <woot-button
                      v-tooltip.top="Previous"
                      variant="smooth"
                      size="tiny"
                      color-scheme="primary"
                      class-names="grey-btn"
                      :is-loading="false"
                      icon="previous"
                      @click="changeCardIndex('decrease')"
                    ></woot-button>
                  </div>
                  <div class="cards">
                    <div
                      class="card"
                      :class="el.constant_name === activeOption.constant_name ? 'card_active':''"
                      v-for="(el) in demoOptionsInview"
                      :key="el.constant_name"
                      @click="onDemoOptionClick(el)"
                    >
                      <h3 class="title">{{el.title}}</h3>
                      <div class="card_icon-wraper">
                        <fluent-icon :icon="el.icon" :size="40" />
                      </div>
                      <p>{{el.description}}</p>
                    </div>
                  </div>

                  <div class="industry_arrow industry_right_arrow">
                    <woot-button
                      v-tooltip.top="Next"
                      variant="smooth"
                      size="tiny"
                      color-scheme="primary"
                      class-names="grey-btn"
                      :is-loading="false"
                      icon="next"
                      @click="changeCardIndex"
                    ></woot-button>
                  </div>
                </div>
              </div>

              <div style="padding-bottom: 10px;">
                <woot-button
                    :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP')"
                    :isLoading="isDemoAutomationSkipLoading"
                    @click="skipOnboardingPopup"
                >{{$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP')}}</woot-button>
                <woot-button classNames="modal_btn" @click="onDemoCardContinue" :isLoading="isDemoAutomationLoading">{{$t('INSTRUCTION_POPUP.ONBOARD_LIST.BUTTON_TEXT')}}</woot-button>
              </div>
            </div>
          </div>
      </section>
      <!-- popuo with vide show end  -->

      <!-- select inbox dropdown list  -->
      <modal modalMainContainerClass="instruction_popup" :show.sync="isExistInboxPopupOpen" :on-close="closeExistInboxPopup">
        <div class="column content-box inbox_container_wrapper">
          <woot-modal-header
            :header-title="$t('INSTRUCTION_POPUP.INBOXES.HEADER')"
            :header-content="$t('INSTRUCTION_POPUP.INBOXES.INTRO')"
          />

          <div class="existing_inboxes">
            <select @change="createDemoByExistInbox"  name="selectedInbox" id="selectedInbox">
              <option value="" >{{$t('INSTRUCTION_POPUP.INBOXES.DEFAULT_INBOX_SELECT')}}</option>
              <option v-for="inbox in existingInboxs" :key="inbox.id" :value="inbox.id">{{inbox.name}}</option>
            </select>
          </div>
          <!-- <div v-if="isDemoAutomationLoading" class="loader-wraper"> -->
          <div v-if="isDemoAutomationLoading" class="inbox_loader_wraper">
            <Spinner size="medium" />
          </div>
        </div>
      </modal>

      <!-- success demo bot createn popup start  -->
      <section class="modal-popup instruction_popup" v-if="isBotSuccessPopupOpen">
        <div class="modal-container modalWidth">
          <div>
            <button class="modal--close" @click="closeBotSuccessPopUp">
              <fluent-icon icon="dismiss"  />
            </button>
            <h3 class="page-title">{{$t('INSTRUCTION_POPUP.SUCCESS_MESSAGE.TITLE')}}</h3>
          </div>
          <div>
              <p>{{$t('INSTRUCTION_POPUP.SUCCESS_MESSAGE.MESSAGE')}}</p>
          </div>
        </div>
      </section>
      <!-- success demo bot createn popup end  -->

      <!-- whatsapp inbox create question popup start  -->
      <!-- whatsapp inbox: modal 1 -->
      <WhatsappInboxCreate
        :isStepOneOpen="isWhatsappQesPopupOpen"
        :onCloseStepOneFn="closeWhatsappQuesPopup"
        :isStepTwoOpen="isWhatsappTwoPopupOpen"
        :onCloseStepTwoFn="closeWhatsappQuotionPopup"
        :isStepThreeOpen="isWhatsappThreePopupOpen"
        :onCloseStepThreeFn="closeWhatsappGiftSelect"
        :onSkipFn="skipWhatsappInboxSetup"
        :selectedCaution="whatsappCautionYesOrNo"
        :onSelectCautionOptionFn="onSelectCautionOption"
      />
      <!-- whatsapp inbox: modal 2 -->
      <!-- whatsapp inbox: modal 3 -->
      <!-- whatsapp inbox create question popup end  -->

    </aside>

  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatList from '../../../components/ChatList';
import ConversationBox from '../../../components/widgets/conversation/ConversationBox';
import PopOverSearch from './search/PopOverSearch';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import WootButton from '../../../components/ui/WootButton.vue';
import Cookies from 'js-cookie';
import FlowAPI from "../settings/bot/FlowApi"
import ApiClient from '../../../../dashboard/api/ApiClient';
import ApiAuth from '../../../../dashboard/api/auth';
import endPoints from '../../../../dashboard/api/endPoints';
import InboxesAPI from '../../../../dashboard/api/inboxes';
import FluentIcon from "../../../../shared/components/FluentIcon/Index.vue"
import {demoShowList} from "./demoShowList";
import Spinner from 'shared/components/Spinner.vue';
import {demoAutoSteps,getContactAttributes,updateDemoOnBoardDataLayer,instructSteps,getUserAttributes,updateUserAttributes, incrementUserStepAttributes} from "../../../helper/demoAutomationHelper";
import Modal from '../../../components/Modal.vue';
import WhatsappInboxCreate from "./InstructPopups/WhatsAppInboxCreate.vue"
import ContestEventPopup from "../../../../dashboard/components/layout/sidebarComponents/popups/ContestEventPopup.vue"
// import ContestEventPopup from "../../../../dashboard/components/layout/sidebarComponents/popups/ContestEventPopup.vue"


export default {
  components: {
    ChatList,
    ConversationBox,
    PopOverSearch,
    WootButton,
    FluentIcon,
    Spinner,
    Modal,
    WhatsappInboxCreate,
    ContestEventPopup,
  },
  mixins: [uiSettingsMixin],
  props: {
    inboxId: {
      type: [String, Number],
      default: 0,
    },
    conversationId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    teamId: {
      type: String,
      default: '',
    },
    conversationType: {
      type: String,
      default: '',
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      showSearchModal: false,
      isWhatsappQesPopupOpen: false,
      isWhatsappTwoPopupOpen: false,
      isWhatsappThreePopupOpen: false,
      whatsappCautionYesOrNo: 'yes',

      isIndustryPoupOpen: false,
      isDemoAutomationLoading: false,
      isDemoAutomationSkipLoading: false,
      // demoOptions: demoShowList,
      demoOptions: [
        {
          icon:"filter-lead",
          constant_name:'lead_qualification',
          title: this.$t('INSTRUCTION_POPUP.ONBOARD_LIST.LEAD.TITLE'),
          description: this.$t('INSTRUCTION_POPUP.ONBOARD_LIST.LEAD.DESCRIPTION'),
          videoUrl:"https://www.youtube.com/embed/oQgchyAgnHE?autoplay=1&loop=1&autopause=0&muted=1&enable_js=1",
        },
        {
          icon:"calendar-rtl",
          constant_name:'appointment_scheduling',
          title: this.$t('INSTRUCTION_POPUP.ONBOARD_LIST.SCHEDULING.TITLE'),
          description: this.$t('INSTRUCTION_POPUP.ONBOARD_LIST.SCHEDULING.DESCRIPTION'),
          videoUrl:"https://www.youtube.com/embed/GVR1whYGgmc?autoplay=1&loop=1&autopause=0&muted=1&enable_js=1",
        },

      ],
      demoOptionsRange:{
        start_index: 0,
        end_index: 3,
      },
      activeOption: {},
      existingInboxs:[],
      isInboxSelectOpen:false,
      // isOnBoardSessionOpen: false,
      isBotSuccessPopupOpen: false,
      undoEndPointList:[],
      banner_gif_composition: {},
      isContestModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      ifAppsumoUser:'ifAppsumoUser',
      chatList: 'getAllConversations',
      currentChat: 'getSelectedChat',
      communalInboxes: 'accounts/getCommunalInboxes',
    }),
    isContactPanelOpen() {
      if (this.currentChat.id) {
        const {
          is_contact_sidebar_open: isContactSidebarOpen,
        } = this.uiSettings;
        return isContactSidebarOpen;
      }
      return false;
    },
    demoOptionsInview(){
      return Array.from(this.demoOptions).slice(this.demoOptionsRange.start_index, this.demoOptionsRange.end_index)
    },
    isExistInboxPopupOpen(){
      return this.existingInboxs.length && this.isInboxSelectOpen;
    },
    onboardingBgs(){
      const octopusAnimBg = require('dashboard/assets/images/banners/octopus-bg.gif');
      return {
        octopusAnimBg,
      }
    }
  },

  mounted() {
    this.$store.dispatch('agents/get');
    this.initialize();
    this.$watch('$store.state.route', () => this.initialize());
    this.$watch('chatList.length', () => {
      this.setActiveChat();
    });

    this.checkWhatsappQuestionPopupOpen();
    // add shortcut keydown handler
    document.addEventListener('keydown', this.handleShortcutModalOpen);

    // if user come from onboarding screen button click
    const query = this.$route.query;
    console.log(this.$route);
    if (query.isOnboardingOpen === 'true') {
      this.handleShortcutModalOpen({ altKey: true, keyCode: 80 });
    }

  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleShortcutModalOpen);
  },

  methods: {
    checkOnboardingRedirect(){
      const user =  Cookies.getJSON('user') || {};
      // && user.onboard_skip = false
      if (user.onboard_limit && user.onboard_limit > 0 && !user.appsumo && !user.onboard_skip) {
        this.$router.push({name:"schedule_session"})
      }
    },
    async checkDemoAutoPopup(){
      const user =  Cookies.getJSON('user') || {};
      const attributes = getUserAttributes();
      const localStep = localStorage.getItem("demo_automation_step");
      const userStep = user?.step ? user?.step : 0;
      if (user && parseInt(userStep) === 0 && user?.role === "administrator" && localStep === null && !user.appsumo && user.plan_id !== 16 && attributes.skip !== 'true') {
        // open the popup
        this.isIndustryPoupOpen = true;
      }else if(user.onboard_limit && user.onboard_limit > 0){
        // check onboard popup open
        // this.checkOnboardPopup();
      }
    },
    async handleShortcutModalOpen(event) {
      // console.log(event);
      // Check if the desired key combination is pressed
      if (event.altKey && event.keyCode === 80) {
        // this.checkDemoAutoPopup();
        this.isIndustryPoupOpen = true;
        await updateUserAttributes('skip','false');
      }
    },
    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected agent
      this.isDemoAutomationLoading = false;
      bus.$emit('newToastMessage', message);
    },
    closeBotSuccessPopUp(){
      this.isBotSuccessPopupOpen = false;
    },
    onConversationLoad() {
      this.fetchConversationIfUnavailable();
    },
    initialize() {
      this.$store.dispatch('setActiveInbox', this.inboxId);
      this.setActiveChat();
    },
    fetchConversationIfUnavailable() {
      if (!this.conversationId) {
        return;
      }
      const chat = this.findConversation();
      if (!chat) {
        this.$store.dispatch('getConversation', this.conversationId);
      }
    },
    findConversation() {
      const conversationId = parseInt(this.conversationId, 10);
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      return chat;
    },
    setActiveChat() {
      if (this.conversationId) {
        const chat = this.findConversation();
        if (!chat) {
          return;
        }
        this.$store.dispatch('setActiveChat', chat).then(() => {
          bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE);
        });
      } else {
        this.$store.dispatch('clearSelectedState');
      }
    },
    onToggleContactPanel() {
      this.updateUISettings({
        is_contact_sidebar_open: !this.isContactPanelOpen,
      });
    },
    onSearch() {
      this.showSearchModal = true;
    },
    closeSearch() {
      this.showSearchModal = false;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async saveAtPeasySdk(){
      const user =  Cookies.getJSON('user') || {};
      window.$peasy.setUser(user.email,{
        name: user.name,
        email: user.email,
      })
      await this.delay(2000);
      window.$peasy.setCustomAttributes({
        invite_date: new Date().toISOString().split('T')[0],
      });
      console.log("saved invite_date at setCustomAttributes = ",window.$peasy.user);
    },
    async undoOperations(headers) {
      try {
        // Reverse the undoEndpoints array to delete the operations in reverse order
        const reversedUndoEndpoints = this.undoEndPointList.reverse();
        for (const endpoint of reversedUndoEndpoints) {
          if (endpoint.type === 'flowbot') {
            await FlowAPI.deleteFlow(endpoint.id);
          }else{
            await axios.delete(endpoint.url, { headers });
          }
        }
        console.log('Undo operations successful');
      } catch (error) {
        console.error('Error during undo operations:', error);
      }
    },
    getRandomInt(min=1, max=99999) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async bookSessionHandler(){
      try {
        const user =  Cookies.getJSON('user') || {};
        const {data:agent} = await axios.get(`/api/v1/accounts/${user.account_id}/assigned_agent`);
        console.log(agent,"agent id");
        if (agent.id) {
          const url = `https://schedule.peasy.ai/book/user/${agent.id}/onboarding`;
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank";
          anchor.click();
        }else if (agent.message) {
          this.showAlert(agent.message);
        }
      } catch (error) {
        console.log(error);
        this.showAlert(error.message);
      }
    },

    closeIndustryPopup(){
      this.isIndustryPoupOpen = false;
    },
    /*
    checkOnboardPopup(){
      const user =  Cookies.getJSON('user') || {};
      console.log(this.currentUser,"Is Apsum user",this.ifAppsumoUser,user);
      // and user is not a apsumo user
      // if(user.onboard_limit && user.onboard_limit > 0 && !this.ifAppsumoUser){
      if(user.onboard_limit && user.onboard_limit > 0 && !user.appsumo){
        console.log("opening Onboard");
        // check onboard popup open
        this.isOnBoardSessionOpen = true;
        this.saveAtPeasySdk();
      }
    },
    */
    changeCardIndex(type) {
      const arrayLength = this.demoOptions.length;
      const step = 3; // Number of elements to move forward or backward

      if (type === 'decrease') {
        this.demoOptionsRange.start_index -= step;
        this.demoOptionsRange.end_index -= step;

        // Check if start index goes below 0
        if (this.demoOptionsRange.start_index < 0) {
          this.demoOptionsRange.start_index = 0;
        }

        // Check if end index goes below step value
        if (this.demoOptionsRange.end_index < step) {
          this.demoOptionsRange.end_index = step;
        }
      } else {
        this.demoOptionsRange.start_index += step;
        this.demoOptionsRange.end_index += step;

        // Check if end index exceeds array length
        if (this.demoOptionsRange.end_index > arrayLength) {
          this.demoOptionsRange.end_index = arrayLength;
        }

        // Check if start index exceeds array length minus step value
        if (this.demoOptionsRange.start_index > arrayLength - step) {
          this.demoOptionsRange.start_index = arrayLength - step;
        }
      }
    },

    onDemoOptionClick(option){
      this.activeOption = option;
    },
    closeExistInboxPopup(){
      this.isInboxSelectOpen = false;
      this.existingInboxs = [];
    },
    async onDemoCardContinue(){
      // check if user has selected a card or not
      if (!this.activeOption.constant_name) {
        return
      }
      this.isDemoAutomationLoading = true;
      // get existing inboxes
      const inboxes = await this.getExistingInboxes();
      console.log(inboxes,"inboxes FFound");
      if(inboxes?.length === 0){
        // alert inbox is required
        this.showAlert('No inbox found');
        this.isDemoAutomationLoading = false;
        return;
      }else if (inboxes.message) {
        this.showAlert(inboxes.message);
      }

      switch (this.activeOption.constant_name) {
        case 'lead_qualification':
          // -------------------------------------

            const {leadQualificationSteps} = instructSteps;
            const {isError:isLdPathErr,data:ldData,errMsg:ldPathErrMsg} = await updateUserAttributes('path',leadQualificationSteps.path);
            if (isLdPathErr) {
              this.showAlert(ldPathErrMsg);
            }

            const {isError:isLdStepErr,data:ldStepData,errMsg:ldStepErrMsg} = await incrementUserStepAttributes(leadQualificationSteps.clickCardStep);
            if (isLdStepErr) {
              this.showAlert(ldStepErrMsg);
            }
            this.isInboxSelectOpen = true;
            this.closeIndustryPopup();
            return;
          /*
          // set the lead path to contact attribute
          // await this.instructLeadQualification()
          // -------------------------------------
          if (inboxes?.length <= 0) {
            await this.createDemoAutomation();
          }
          */
          break;

        case 'appointment_scheduling':
          // -------------------------------------
          // set the schedule  path to contact attribute
          // set path="scheduling", & step=1

            const {schedulingSteps} = instructSteps;
            const {isError:isPathErr,data,errMsg:pathErrMsg} = await updateUserAttributes('path',schedulingSteps.path);
            if (isPathErr) {
              this.showAlert(pathErrMsg);
            }

            const {isError:isStepErr,data:stepData,errMsg:stepErrMsg} = await incrementUserStepAttributes(schedulingSteps.clickCardStep);
            if (isStepErr) {
              this.showAlert(stepErrMsg);
            }
            this.isInboxSelectOpen = true;
            this.closeIndustryPopup();
            return;
          /*
          // await this.instructAppointmentSchedule();
          // -------------------------------------
          if (inboxes?.length <= 0) {
            await this.createDemoAppointmentScheduling();
          }
          */
          break;

        default:
          break;
      }
    },
    async createDemoByExistInbox(event){
      // check which card is selected and call fn based on the card name
      const selectedInboxId = event.target.value;
      const selectedExistInbox = this.existingInboxs.find(inbox=>inbox.id?.toString() === selectedInboxId);
      const attributes = getUserAttributes();
      console.log(attributes,'attributes in select inbox');

      if (selectedExistInbox && this.activeOption.constant_name === 'lead_qualification') {
        const {leadQualificationSteps} = instructSteps;
        if (attributes.path === leadQualificationSteps.path && attributes.step === leadQualificationSteps.clickCardStep) {
          const {isError:isInboxErr,data,errMsg:inboxErrMsg} = await updateUserAttributes('inbox_id', selectedExistInbox.id);
          if (isInboxErr) this.showAlert(inboxErrMsg);
          const {isError:isStepErr,data:stepData,errMsg:stepErrMsg} = await incrementUserStepAttributes(leadQualificationSteps.selectInboxIdStep);
          if (isStepErr) this.showAlert(stepErrMsg);
        }
        this.$router.push(`/app/accounts/${this.currentUser.account_id}/settings/teams/new`);
        return;
        // await this.createDemoAutomation(selectedExistInbox.name,selectedExistInbox.id,true);
      }else if(selectedExistInbox && this.activeOption.constant_name === 'appointment_scheduling'){
        const {schedulingSteps} = instructSteps;
        if (attributes.path === schedulingSteps.path && attributes.step === schedulingSteps.clickCardStep) {
          const {isError:isInboxErr,data,errMsg:inboxErrMsg} = await updateUserAttributes('inbox_id', selectedExistInbox.id);
          if (isInboxErr) this.showAlert(inboxErrMsg);

          // increase step+1
          const {isError:isStepErr,data:stepData,errMsg:stepErrMsg} = await incrementUserStepAttributes(schedulingSteps.selectInboxIdStep);
          if (isStepErr) this.showAlert(stepErrMsg);
        }

        this.$router.push(`/app/accounts/${this.currentUser.account_id}/profile/settings`);
        return;
        // await this.createDemoAppointmentScheduling(selectedExistInbox.name,selectedExistInbox.id,true)
      }
    },


    async getExistingInboxes(){
      try {
        const {data:dataPayload } = await InboxesAPI.get();
        console.log(dataPayload,"inboxesList in getExistingInboxes",dataPayload?.payload);
        if (dataPayload?.payload?.length > 0) {
          this.existingInboxs = dataPayload.payload;
          return dataPayload.payload;
        }
        return [];
      } catch (error) {
        console.log(error);
        this.showAlert("Failed to load inboxes");
      }
      /*
      const APIProvider = new ApiClient();
      const accountId = window.location.pathname.split('/')[3];
      const mainUrl = `${APIProvider.baseUrl()}/accounts/${accountId}`;
      const {data:inboxesExistRes} = await axios.get(`${mainUrl}/inboxes`);
      if(inboxesExistRes.payload?.length > 0){
        this.existingInboxs = inboxesExistRes.payload;
        // this.activeOption.constant_name == "" set path to custom attribute
      }
      return inboxesExistRes.payload;
      */
    },



    async createDemoAutomation(inboxName='whatsapp',inbox_id=null,isExistInbox=false){
      // -------------------------------------
      // await this.instructLeadQualification(inboxName,inbox_id,isExistInbox);
      // return;
      // -------------------------------------

      this.isDemoAutomationLoading = true;
      this.undoEndPointList = [];
      // call to create the automation
      // POST: https://chat.peasy.ai/api/v1/accounts/66/inboxes

      const APIProvider = new ApiClient();
      const accountId = window.location.pathname.split('/')[3];
      const mainUrl = `${APIProvider.baseUrl()}/accounts/${accountId}`;
      const authData = ApiAuth.getAuthData();
      const currentUser = ApiAuth.getCurrentUser();

      // Set the access-token in the headers
      const headers = {'access-token': authData['access-token']};

      try {
        // part 1
        // 1.1) create communal whatsapp inbox if not exist, if exist, use that inbox_id
        // let inboxName = "whatsapp";
        // let inbox_id = null;
        // check if communal inbox exist
        /*
        const {data:inboxesExistRes} = await axios.get(`${mainUrl}/inboxes`);
        if(inboxesExistRes.payload?.length > 0){
          const firstInboxElement = inboxesExistRes.payload[0];
          inboxName =  firstInboxElement?.name;
          inbox_id = firstInboxElement?.id;
        }
        */

        // create communal inbox
        const createInboxBody = {
          name: inboxName,
          channel:{
            type:"whatsapp",
            phone_number:"+60162999739",
            communal_type:true,
            provider_config:{api_key:"api_key"}
          }
        }
        if(!inbox_id){
          const {data:createInboxResult} = await axios.post(`${mainUrl}/inboxes`,createInboxBody,{ headers });
          console.log('1) createInboxResult',createInboxResult,);
        }


        // 1.2) add agent to inbox /api/v1/accounts/4/inbox_members
        if (!inbox_id) {
          const {data:inboxesRes} = await axios.get(`${mainUrl}/inboxes`);
          inbox_id = inboxesRes.payload[0]?.id;
        }

        const {data:agents} = await axios.get(`${mainUrl}/agents`);
        const agent_id = agents[0]?.id;


        const addColaboratorBody = {
          inbox_id,
          user_ids:[agent_id]
        }
        const {data:addColaboratorResult} = await axios.patch(`${mainUrl}/inbox_members`,addColaboratorBody,{ headers });


        // 1.3) enable auto assignment
        const formData = new FormData();
        formData.append('name', inboxName);
        formData.append('enable_auto_assignment', true);
        formData.append('enable_email_collect', true);
        formData.append('csat_survey_enabled', false);
        formData.append('allow_messages_after_resolved', true);
        formData.append('greeting_enabled', false);
        formData.append('greeting_message', '');
        formData.append('channel[selected_feature_flags][]', '');
        formData.append('channel[widget_color]', null);
        formData.append('channel[website_url]', null);
        formData.append('channel[webhook_url]', undefined);
        formData.append('channel[welcome_title]', '');
        formData.append('channel[welcome_tagline]', '');
        formData.append('channel[reply_time]', 'in_a_few_minutes');
        formData.append('channel[hmac_mandatory]', false);
        formData.append('channel[tweets_enabled]', false);
        formData.append('channel[continuity_via_email]', undefined);

        const {data:enableAutoAssignResult} = await axios.patch(`${mainUrl}/inboxes/${inbox_id}`, formData, { headers });


        // part 2
        // 2.1) create a team: add user as team member, and turn on auto assign
        // creating two teams
        const teamList = [
          {
            description: 'Residence sales response team',
            name: 'residence_sales',
            allow_auto_assign: true
          },
          {
            description: 'Investment sales response team',
            name: 'investment_sales',
            allow_auto_assign: true
          },
        ]

        const [{data:residenceTeam},{data:investmentTeam}] = await Promise.all(teamList
        .map(teamBody=>axios
        .post(`${mainUrl}/teams`, teamBody, { headers })
        .then(res=>{
          this.undoEndPointList.push({url:`${mainUrl}/teams/${res.data?.id}`})
          return res;
        })))
        console.log('4) residenceTeam',residenceTeam);
        console.log('5) investmentTeam',investmentTeam);

      /*
        const createTeamBody = {
          description: 'a whatsapp team',
          name: 'whatsapp team',
          allow_auto_assign: true
        };

        const {data:createTeamResult} = await axios.post(`${mainUrl}/teams`, createTeamBody, { headers })

      */

        // 2.2) add user as a team member
        const addUserInTeamBody = {user_ids: [agent_id],};
        const [{data:residenceTeamMembers},{data:investmentTeamMembers}] = await Promise.all([residenceTeam.id,investmentTeam.id]
          .map(teamId =>axios.patch(`${mainUrl}/teams/${teamId}/team_members`, addUserInTeamBody, { headers })));
        console.log('6) residenceTeamMembers',residenceTeamMembers,this.undoEndPointList);
        console.log('7) investmentTeamMembers',investmentTeamMembers);

        /*
        const {data:addUserInTeamResult} = await axios.patch(`${mainUrl}/teams/${createTeamResult.id}/team_members`, addUserInTeamBody, { headers })

        */

        // 3.0) create custom attributes
        const customAttributesBodys =[
          {
            attribute_display_name:"Purpose",
            attribute_description:"Demo Purpose",
            attribute_model:1,
            attribute_display_type:0,
            attribute_key:"purpose",
            attribute_values:[]
          },
          {
            attribute_display_name:"Botflag",
            attribute_description:"Demo Purpose",
            attribute_model:1,
            attribute_display_type:0,
            attribute_key:"botflag",
            attribute_values:[]
          },
        ]
        const customAttributesRes = await Promise.all(customAttributesBodys
          .map(attrBody =>axios
          .post(`${mainUrl}/custom_attribute_definitions`, attrBody, { headers })
          .then(res=>{
            this.undoEndPointList.push({url:`${mainUrl}/custom_attribute_definitions/${res.data?.id}`})
            return res;
          })))
        console.log('8) customAttributesRes',customAttributesRes,this.undoEndPointList);


        // 3) add template message (canned response)
        const short_code_tpl_msg = "templatehello" + this.getRandomInt();  // join random number to make unique
        const content_tpl_msg = "Hi {{contact.name}}, {{agent.name}} from Peasy here. Is this a good time to talk?";
        const formDataTemplateMsg = new FormData();
        formDataTemplateMsg.append('short_code', short_code_tpl_msg);
        formDataTemplateMsg.append('content', content_tpl_msg);
        formDataTemplateMsg.append('inbox_id', inbox_id);
        formDataTemplateMsg.append('file', '');
        formDataTemplateMsg.append('buttonsData', '[{"name":"Say Hi","code":"Sa2252262"}]');
        formDataTemplateMsg.append('language', 'en');
        formDataTemplateMsg.append('templateType', 'MARKETING');
        if (!isExistInbox) {
          formDataTemplateMsg.append('onboard', "yes");
        }

        const {data:addtemplateMsgResult} = await axios.post(`${mainUrl}/create_template_message`, formDataTemplateMsg, { headers })
        this.undoEndPointList.push({url:`${mainUrl}/canned_responses/${addtemplateMsgResult?.id}`})

        // 5.1) create chatbot id for campaign (incomming_message)
        const demoBotName = "incomming_msg_bot";
        const bot_template = {id:"4-lead-qualification-template-Di5a90"};
        const createFlowResponse = await FlowAPI.createFlow(currentUser.id, demoBotName,bot_template,currentUser.account_id);
        console.log('10) createFlowResponse',createFlowResponse);


        // 5.2) get the bot_id of the currently created bot
        const botList = await FlowAPI.getBotsByUserId(currentUser.id,true,currentUser.account_id);
        const new_bot_id = botList.find(bot=>bot.name === demoBotName)?.botid;
        if (new_bot_id) {
          this.undoEndPointList.push({
            type:"flowbot",
            id: new_bot_id,
          })
        }

        // update the bot flowdata to export
        const flowData = await FlowAPI.getFlowById(new_bot_id,currentUser.id);
        // console.log(flowData,"flowData");
        const saveFlowResponse = await FlowAPI.exportFlow(new_bot_id,flowData?.data);
        console.log(saveFlowResponse,"saveFlowResponse");

        // 6) Create automation list
        const automationBodyList = [
          /*
          // 1. create new contact
          {
            name:"new contact created no botflag",
            description:"Contact created when status new and custom attribute botflag is not present",
            event_name:"contact_created",
            conditions:[
                {
                    attribute_key:"status_id",
                    filter_operator:"equal_to",
                    values:[10],
                    query_operator:"and"
                },
                {
                    attribute_key:"botflag",
                    filter_operator:"is_not_present",
                    values:["on"]
                }
            ],
            actions:[
                {
                    action_name:"send_message",
                    action_params:[inbox_id],
                    message_content:"Hi {{contact.name}}, {{agent.name}} from Peasy here. Is this a good time to talk?",
                    template_name: "templatehello",
                }
            ]
          },
          */
          // 2. msg create incommin botflag 'on'
          {
            name:"new_incomming_msg_with_botflag_on",
            description:"Create new message with no botflag",
            event_name:"message_created",
            conditions:[
                {
                  attribute_key:"message_type",
                  filter_operator:"equal_to",
                  values:["incoming"],
                  query_operator:"and"
                },
                {
                    attribute_key:"botflag",
                    filter_operator:"equal_to",
                    values:["on"]
                }
            ],
              actions:[
                  {
                      action_name:"assign_bot",
                      action_params:[inbox_id],
                      bot_id: new_bot_id
                  }
              ]
          },

          // 3. create incommin msg botflag not present and not equal 'on'
          {
            name:"new_incomming_msg_no_botflag",
            description:"Create new message with no botflag",
            event_name:"message_created",
            conditions:[
                {
                  attribute_key:"message_type",
                  filter_operator:"equal_to",
                  values:["incoming"],
                  query_operator:"and"
                },
                {
                  attribute_key:"botflag",
                  filter_operator:"is_not_present",
                  values:["on"],
                  query_operator:"and"
                },
                {
                  attribute_key:"botflag",
                  filter_operator:"not_equal_to",
                  values:["on"]
                }
            ],
              actions:[
                  {
                      action_name:"assign_bot",
                      action_params:[inbox_id],
                      bot_id: new_bot_id
                  }
              ]
          },



          // 4. contact updated purpose='ownstay' and not equal disqualified
          {
            name:"contact_update_purposeOnstay_not-disqualify",
            description:"Contact update purpose ownstay and not equal disqualify",
            event_name:"contact_updated",
            conditions:[
                {
                    attribute_key:"status_id",
                    filter_operator:"not_equal_to",
                    values:[25],
                    query_operator:"and"
                },
                {
                    attribute_key:"purpose",
                    filter_operator:"equal_to",
                    values:["ownstay"]
                }
            ],
            actions:[
                {
                    action_name:"assign_team",
                    action_params:[residenceTeam.id]    // residence_sale team id
                }
            ]
          },
          // 5. contact updated purpose='investment' and not equal disqualified
          {
            name:"contact_update_investmentOnInvestment_not-disqualif",
            description:"Contact update purpose ownstay and not equal disqualify",
            event_name:"contact_updated",
            conditions:[
                {
                    attribute_key:"status_id",
                    filter_operator:"not_equal_to",
                    values:[25],
                    query_operator:"and"
                },
                {
                    attribute_key:"purpose",
                    filter_operator:"equal_to",
                    values:["investment"]
                }
            ],
            actions:[
                {
                    action_name:"assign_team",
                    action_params:[investmentTeam.id]    // investment_sales team id
                }
            ]
          },

        ];
        /*
        // All in parallel
        const automationsResult = await Promise.all(automationBodyList
        .map(autoBody => axios
        .post(`${mainUrl}/automation_rules`, autoBody, { headers })
        .then(res=>{
          this.undoEndPointList.push({url:`${mainUrl}/automation_rules/${res.data?.id}`})
          return res;
        })
        ));
        */
        // request one by one
        for (const autoBody of automationBodyList) {
            const res = await axios.post(`${mainUrl}/automation_rules`, autoBody, { headers });
            this.undoEndPointList.push({ url: `${mainUrl}/automation_rules/${res.data?.id}` });
        }

        /*
        // 7)   create demo contact
        const contactbody = {
          name:"Test name",
          email:"",
          phone_number:"+6012345678",
          is_communal:true,
          additional_attributes:{
              description:"",
              company_name:"",
              social_profiles:{twitter:"",facebook:"",linkedin:"",github:""}
          }
        }
        const {data:contactRes} = await axios.post(`${mainUrl}/contacts`, contactbody, { headers });
        this.undoEndPointList.push({url:`${mainUrl}/contacts/${contactRes?.id}`})
        console.log('13) contactRes',contactRes);
        */


        // fetch to set step=1
        const stepResult = await axios.post(`${mainUrl}/increment_step`, {step: "1"}, { headers });
        console.log('14) stepResult',stepResult);
        localStorage.setItem('demo_automation_step',1);

        console.log("EExisting UNDO Urls", this.undoEndPointList);
        // close the popup and loader
        this.isDemoAutomationLoading = false;
        this.isIndustryPoupOpen = false;
        this.isBotSuccessPopupOpen = true;
        // this.checkOnboardPopup();

      } catch (error) {
        console.log(error,error.response);
        console.log("EExisting UNDO Urls", this.undoEndPointList);
        await this.undoOperations(headers);
        this.isDemoAutomationLoading = false;
        this.isIndustryPoupOpen = false;
        this.isBotSuccessPopupOpen = false;

        if (error.response) {
          // The request was made and the server responded with a status code
          // Extract the error message from the response
          const errorMessage = error.response.data.message;

          // Display the error message using showAlert() or any other method
          this.showAlert(errorMessage);
        } else if (error.request) {
          // The request was made but no response was received
          // console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered the error
          console.error('Error:', error.message);
          this.showAlert(error.message);
        }
      }

    },

    // --------------------------Instruct Tutorial Start

    async instructLeadQualification(inboxName='whatsapp',inbox_id=null,isExistInbox=false){
      // delete this function andd allow below function in real
      // check the path for scheduling and push that route
      const attributes = getContactAttributes();
      const {lead} = demoAutoSteps;
      const {onboard_path,[lead.stepName]:leadStepNumber} = attributes;
      // the schedule_path and step will be 0/undefined
      console.log(onboard_path,leadStepNumber,"path+number",attributes,"usr",this.currentUser);
      if (leadStepNumber === undefined) {
        const onboardAttribute = {
          [lead.stepName]: lead.actions.createTeam.open_step,
          onboard_path: lead.path,
        };
        window.$peasy.setCustomAttributes(onboardAttribute);
        updateDemoOnBoardDataLayer(onboardAttribute);
        // navigate to this page
        // this.$router.push(`/app/accounts/${this.currentUser.account_id}/settings/canned-response/list`);
      }
    },
    async instructAppointmentSchedule(inboxName='whatsapp',inbox_id=null,isExistInbox=false){
      // delete this function andd allow below function in real
      // check the path for scheduling and push that route
      const attributes = getContactAttributes();
      const {schedule} = demoAutoSteps;
      const {onboard_path,[schedule.stepName]:scheduleStepNumber} = attributes;
      // the schedule_path and step will be 0/undefined
      console.log(onboard_path,scheduleStepNumber,"path+number",attributes,"usr",this.currentUser);
      if (scheduleStepNumber === undefined) {
        const onboardAttribute = {
          [schedule.stepName]: schedule.actions.addTemplateMsg.open_step,
          onboard_path: schedule.path,
        };
        window.$peasy?.setCustomAttributes(onboardAttribute);
        updateDemoOnBoardDataLayer(onboardAttribute);
        // navigate to this page
        // this.$router.push(`/app/accounts/${this.currentUser.account_id}/settings/canned-response/list`);
      }
    },

    // --------------------------Instruct Tutorial End
    // create automation for scheduling appointment
    async createDemoAppointmentScheduling(inboxName='whatsapp',inbox_id=null,isExistInbox=false){
      // -------------------------------------
      // await this.instructAppointmentSchedule(inboxName,inbox_id,isExistInbox);
      // return;
      // -------------------------------------

      this.isDemoAutomationLoading = true;
      this.undoEndPointList = [];

      const APIProvider = new ApiClient();
      const accountId = window.location.pathname.split('/')[3];
      const mainUrl = `${APIProvider.baseUrl()}/accounts/${accountId}`;
      const authData = ApiAuth.getAuthData();
      const currentUser = ApiAuth.getCurrentUser();
      const headers = {'access-token': authData['access-token']};

      try {
        // 1.1) create communal inbox
        const createInboxBody = {
          name: inboxName,
          channel:{
            type:"whatsapp",
            phone_number:"+60162999739",
            communal_type:true,
            provider_config:{api_key:"api_key"}
          }
        }
        if(!inbox_id){
          const {data:createInboxResult} = await axios.post(`${mainUrl}/inboxes`,createInboxBody,{ headers });
        }
        if (!inbox_id) {
          const {data:inboxesRes} = await axios.get(`${mainUrl}/inboxes`);
          inbox_id = inboxesRes.payload[0]?.id;
        }

        /*
        // 1.2) assign user as collaborator to that inbox
        const {data:inboxesRes} = await axios.get(`${mainUrl}/inboxes`);
        const inbox_id = inboxesRes.payload[0]?.id;
        */

        const {data:agents} = await axios.get(`${mainUrl}/agents`);
        const agent_id = agents[0]?.id;

        const addColaboratorBody = {
          inbox_id,
          user_ids:[agent_id]
        }
        const {data:addColaboratorResult} = await axios.patch(`${mainUrl}/inbox_members`,addColaboratorBody,{ headers });


        // 1.3) enable auto assignment
        const formData = new FormData();
        formData.append('name', inboxName);
        formData.append('enable_auto_assignment', true);
        formData.append('enable_email_collect', true);
        formData.append('csat_survey_enabled', false);
        formData.append('allow_messages_after_resolved', true);
        formData.append('greeting_enabled', false);
        formData.append('greeting_message', '');
        formData.append('channel[selected_feature_flags][]', '');
        formData.append('channel[widget_color]', null);
        formData.append('channel[website_url]', null);
        formData.append('channel[webhook_url]', undefined);
        formData.append('channel[welcome_title]', '');
        formData.append('channel[welcome_tagline]', '');
        formData.append('channel[reply_time]', 'in_a_few_minutes');
        formData.append('channel[hmac_mandatory]', false);
        formData.append('channel[tweets_enabled]', false);
        formData.append('channel[continuity_via_email]', undefined);

        const {data:enableAutoAssignResult} = await axios.patch(`${mainUrl}/inboxes/${inbox_id}`, formData, { headers });



        // 2.1) create template message 'templatehello' (canned response)
        const templateMessages = [
          {
            short_code: "template_confirmation-" + this.getRandomInt(),
            content: "Hi {{contact.name}}, thanks for scheduling an appointment with us. Our appointment at {{meeting.location}] is confirmed on {{meeting.friendly_time}}",
            inbox_id,
            // onboard: 'yes',
            file:"",
            buttonsData: '[{"name":"Say Hi","code":"Sa2252262"}]',
            templateType:"MARKETING"
          },
          {
            short_code: "template_reschedule-"  + this.getRandomInt(),
            content: "Hi {{contact.name}}, sorry that you couldn’t make it. Reply “Yes” if you like to reschedule.",
            inbox_id,
            // onboard: 'yes',
            file:"",
            buttonsData: '[{"name":"Say Hi","code":"Sa2252262"}]',
            templateType:"MARKETING"
          },
          {
            short_code: "template_reminder-" + this.getRandomInt(),
            content: "Hi {{contact.name}}, just a friendly reminder that our appointment will be held in the next 60 minutes.",
            inbox_id,
            // onboard: 'yes',
            file:"",
            buttonsData: '[{"name":"Say Hi","code":"Sa2252262"}]',
            templateType:"MARKETING"
          },
          {
            short_code: "template_accept-" + this.getRandomInt(),
            content: "Hi {{contact.name}}, thanks for accepting the invite. Please join the call using your desktop/laptop and not mobile phone.",
            inbox_id,
            // onboard: 'yes',
            file:"",
            buttonsData: '[{"name":"Say Hi","code":"Sa2252262"}]',
            templateType:"MARKETING"
          },
        ].map(msgEl =>{
          if (!isExistInbox) {
            msgEl.onboard = 'yes'
          }
          return msgEl;
        })

        console.log("Scheduling tpl messages",templateMessages);

        const formedBodyTemplates = templateMessages.map(tpl=>{
          const formDataTemplateMsg = new FormData();
          formDataTemplateMsg.append('short_code', tpl.short_code);
          formDataTemplateMsg.append('content', tpl.content);
          formDataTemplateMsg.append('inbox_id', tpl.inbox_id);
          formDataTemplateMsg.append('onboard', tpl.onboard);
          formDataTemplateMsg.append('file', tpl.file);
          formDataTemplateMsg.append('buttonsData', tpl.buttonsData);
          formDataTemplateMsg.append('language', 'en');
          formDataTemplateMsg.append('templateType', tpl.templateType);
          return formDataTemplateMsg;
        })
        const templateMessagesRes = await Promise.all(formedBodyTemplates
          .map(cannedBody =>axios
          .post(`${mainUrl}/create_template_message`, cannedBody, { headers })
          .then(res=>{
            this.undoEndPointList.push({url:`${mainUrl}/canned_responses/${res.data?.id}`})
            return res;
          })))


        // 3.1) create custom_attributes called 'appointment_date' & 'botflag'
        const customAttributesBodys =[
          {
            attribute_display_name:"appointment_date",
            attribute_description:"Appointment date",
            attribute_model:1,
            attribute_display_type: 5,  // 5 is the 'date' type
            attribute_key:"appointment_date",
            attribute_values:[]
          },
          {
            attribute_display_name:"Botflag",
            attribute_description:"Demo Purpose",
            attribute_model:1,
            attribute_display_type:0,
            attribute_key:"botflag",
            attribute_values:[]
          },
        ]
        const customAttributesRes = await Promise.all(customAttributesBodys
          .map(attrBody =>axios
          .post(`${mainUrl}/custom_attribute_definitions`, attrBody, { headers })
          .then(res=>{
            this.undoEndPointList.push({url:`${mainUrl}/custom_attribute_definitions/${res.data?.id}`})
            return res;
          })))


        // 4.1) create a chatbot ids from template 4-meetingchatbot-vHcxsW and 4-carworkshop-JIUeae
        const chatBotList = [
          {
            botName: "meetingchatbot",
            bot_template: {id:'4-meetingchatbot-vHcxsW'}
          },
          {
            botName: "carworkshop",
            bot_template: {id:'4-carworkshop-JIUeae'}
          },
        ];

        for (const chatBot of chatBotList) {
          const createFlowResponse = await FlowAPI.createFlow(currentUser.id,chatBot.botName,chatBot.bot_template,currentUser.account_id);

          // Get the bot_id of the currently created bot
          const botList = await FlowAPI.getBotsByUserId(currentUser.id,true,currentUser.account_id);

          const new_bot_id = botList.find(bot => bot.name === chatBot.botName)?.botid;

          if (new_bot_id) {
            this.undoEndPointList.push({
              type: "flowbot",
              id: new_bot_id,
            });
          }

          // Update the bot flowdata to export
          const flowData = await FlowAPI.getFlowById(new_bot_id, currentUser.id);
          const saveFlowResponse = await FlowAPI.exportFlow(new_bot_id,flowData?.data);

          // console.log('Save Flow Response:', saveFlowResponse);
        }

        const botList = await FlowAPI.getBotsByUserId(currentUser.id,true,currentUser.account_id);

        /*
        const createBotsRes = await Promise.all(chatBotList
        .map(bot => FlowAPI.createFlow(currentUser.id, bot.botName,bot.bot_template,currentUser.account_id)))

        // 4.2) get the bot_id of the currently created bot
        const botList = await FlowAPI.getBotsByUserId(currentUser.id,true,currentUser.account_id);
        ['carworkshop','meetingchatbot'].forEach(botName=>{
          this.undoEndPointList.push({
            type:"flowbot",
            id: botList.find(bot=>bot.name === botName)?.botid,
          })
        })
        */


        // 5.1) create the automation list
        const automationBodyList = [
          // msg create incommin botflag 'on'
          {
            name:"new_incomming_msg_with_botflag_on",
            description:"Create new message with no botflag",
            event_name:"message_created",
            conditions:[
                {
                  attribute_key:"message_type",
                  filter_operator:"equal_to",
                  values:["incoming"],
                  query_operator:"and"
                },
                {
                    attribute_key:"botflag",
                    filter_operator:"equal_to",
                    values:["on"]
                }
            ],
              actions:[
                  {
                      action_name:"assign_bot",
                      action_params:[inbox_id],
                      bot_id: botList.find(bot=>bot.name === 'carworkshop')?.botid,
                  }
              ]
          },

          // create incommin msg botflag not present and not equal 'on'
          {
            name:"new_incomming_msg_no_botflag",
            description:"Create new message with no botflag",
            event_name:"message_created",
            conditions:[
                {
                  attribute_key:"message_type",
                  filter_operator:"equal_to",
                  values:["incoming"],
                  query_operator:"and"
                },
                {
                    attribute_key:"botflag",
                    filter_operator:"is_not_present",
                    values:["on"],
                    query_operator:"and"
                },
                {
                  attribute_key:"botflag",
                  filter_operator:"not_equal_to",
                  values:["on"]
                }
            ],
              actions:[
                  {
                      action_name:"assign_bot",
                      action_params:[inbox_id],
                      bot_id: botList.find(bot=>bot.name === 'carworkshop')?.botid,
                  }
              ]
          },



          // event created on meeting confirm
          {
            name:"create_meeting",
            description:"Create a new meeting",
            event_name:"event_created",
            conditions:[
              {
                values: ["meeting_confirmed"],
                attribute_key: "event_name",
                query_operator: "or",
                filter_operator: "equal_to"
              },
              {
                values: ["meeting_rejected"],
                attribute_key: "event_name",
                filter_operator: "equal_to"
              }
            ],
            actions:[
                {
                  action_name:"assign_bot",
                  // action_params:[8],   // what would be the dynamic team id
                  action_params:[inbox_id],   // what would be the dynamic value /
                  bot_id: botList.find(bot=>bot.name === 'meetingchatbot')?.botid,
                }
            ]
          }
        ];

        /*
        const automationsResult = await Promise.all(automationBodyList
        .map(autoBody => axios
        .post(`${mainUrl}/automation_rules`, autoBody, { headers })
        .then(res=>{
          this.undoEndPointList.push({url:`${mainUrl}/automation_rules/${res.data?.id}`})
          return res;
        })
        ));
        */
       // calling automation one by one to maintain call sequence
        for (const autoBody of automationBodyList) {
            const res = await axios.post(`${mainUrl}/automation_rules`, autoBody, { headers });
            this.undoEndPointList.push({ url: `${mainUrl}/automation_rules/${res.data?.id}` });
        }

        // fetch to set step=1
        const stepResult = await axios.post(`${mainUrl}/increment_step`, {step: "1"}, { headers });
        localStorage.setItem('demo_automation_step',1);
        console.log("EExisting UNDO Urls", this.undoEndPointList);

        // close the popup and loader
        this.isDemoAutomationLoading = false;
        this.isIndustryPoupOpen = false;
        this.isBotSuccessPopupOpen = true;
        // this.checkOnboardPopup();

       } catch (error) {
         console.log(error,error.response);
        console.log("EExisting UNDO Urls", this.undoEndPointList);
        await this.undoOperations(headers);
        this.isDemoAutomationLoading = false;
        this.isBotSuccessPopupOpen = false;

        if (error.response) {
          // The request was made and the server responded with a status code
          // Extract the error message from the response
          const errorMessage = error.response.data.message;

          // Display the error message using showAlert() or any other method
          this.showAlert(errorMessage);
        } else if (error.request) {
          // The request was made but no response was received
          // console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered the error
          console.error('Error:', error.message);
          this.showAlert(error.message);
        }
      }
    },

    closeOnboardPopup(){
      this.isOnBoardSessionOpen = false;
    },
    async checkWhatsappQuestionPopupOpen(){
      try {
        const response = await InboxesAPI.get();
        const inboxesList = response.data?.payload || [];
        const isInboxExist = inboxesList.some(el=>el.channel_type  && el.channel_type.toLowerCase().includes('whatsapp'));
        const attributes = getUserAttributes();

        if (!isInboxExist && attributes.skipWhatsappInbox !== 'true') {  // allow this to new skip attribute. user.customAttributes.skipWhatsappInbox
          this.isWhatsappQesPopupOpen = true;
        }else{
          this.checkDemoAutoPopup();
        }
      } catch (error) {
        console.log(error);
        this.checkDemoAutoPopup();
      }
    },
    async skipWhatsappInboxSetup(){
      // await this.skipOnboardingPopup();
      this.isDemoAutomationSkipLoading = true;
      await updateUserAttributes('skipWhatsappInbox','true');
      this.closeIndustryPopup();
      this.isDemoAutomationSkipLoading = false;

      this.checkDemoAutoPopup();
      this.isWhatsappQesPopupOpen = false;
    },
    closeWhatsappQuesPopup(){
      this.isWhatsappQesPopupOpen = false;
      this.isWhatsappTwoPopupOpen = true;
    },
    closeWhatsappQuotionPopup(){
      this.isWhatsappTwoPopupOpen = false;
      this.isWhatsappThreePopupOpen = true;
    },
    closeWhatsappGiftSelect(){
      this.isWhatsappThreePopupOpen = false;
    },
    onSelectCautionOption(value){
      console.log(value,"Got value change");
      this.whatsappCautionYesOrNo = value;
    },
    async setupWhatsAppInbox(){
      /*
      // setup with user.custom_attributes
      const {inboxCreateSteps} = instructSteps;
      // get user.attributes
      const {path:onboard_path} = getUserAttributes();
      const pathResult = await updateUserAttributes(inboxCreateSteps.path,inboxCreateSteps.inboxAdd);
      // const stepResult = await updateUserAttributes()
    */

      // this.currentUser.account_id
      // const contactAttributes = getContactAttributes();
      // const {whatsAppInbox} = demoAutoSteps;
      // increse the step by window.$peasy.set({lead_step: +1})
      // const onboardAttribute = {
      //   [whatsAppInbox.stepName]: whatsAppInbox.actions.inboxAdd.open_step,
      //   onboard_path: whatsAppInbox.path,
      // };
      // not on contact, save the step in user.customattributes
      // window.$peasy?.setCustomAttributes(onboardAttribute);
      // updateDemoOnBoardDataLayer(onboardAttribute);

      // const url = endPoints('whatsappAccountSetup').url(this.currentUser.account_id);
      // this.$router.push(url);

    },

    async skipOnboardingPopup(){
      this.isDemoAutomationSkipLoading = true;
      await updateUserAttributes('skip','true');
      this.closeIndustryPopup();
      this.isDemoAutomationSkipLoading = false;
    },

    //  @update:isOpen="onUpdateContestModalOpen"
    onUpdateContestModalOpen(isOpen) {
      this.isContestModalOpen = isOpen;
      console.log("Modal open state in parent:", this.isContestModalOpen);
    },


  },
};
</script>
<style lang="scss" scoped>
.conversation-page {
  display: flex;
  width: 100%;
  height: 100%;
}

.modal-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0 15px 2px #e5e3e3;
}
.modal-container{
  padding: 2rem;
}
.modal_btn{
  display: inline-block;
  margin: 0;
  // margin-top: 2rem;
}
.modalWidth{
  max-width: 90vw;
}
.industry-popup{
  background-color: #e5e3e3;
  .wrapper_bg{
    img{
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  hr{
    margin: 0;
    border-color: #d3d3d3;
  }

  .head{
    background-color:  var(--w-25);
    padding: 2rem 2rem 1rem;
    position: relative;

    .close{
      position: absolute;
      top: 10px;
      right: 10px;

      &hover{
        opacity: 1;
      }
    }


  }
  .body_wrap{
    // padding: 100px 100px 50px 100px;
    padding: 120px 140px 50px;
  }
  .body{
    // --bg_onboard_color: #ffffff, #dcbdfb;
    // background-image: -moz-linear-gradient(to right top, var(--bg_onboard_color));
    // background-image: -webkit-linear-gradient(to right top, var(--bg_onboard_color));
    // background-image: linear-gradient(to right top, var(--bg_onboard_color));
    position: relative;
    // padding: 0 1rem 1rem 1rem
    border-radius: 20px;
    text-align: center;
    max-width: 550px;

    .banner_title{
      background-color: var(--w-500);
      border-radius: 20px;
      color: #fff;
    }
    .content_box{
      padding: 20px;
    }

    .card_video{
      width: fit-content;
      margin: 1.5rem auto;
      transition: height 0.3s ease-in-out;
      overflow: hidden;
      height: 0;

      position: relative;
      width: 100%;
      // padding-bottom: 56.25%; // 16:9 aspect ratio
      overflow: hidden;

      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .card_video.hidden {
      // height: 280px;
      padding-bottom: 56.25%; // 16:9 aspect ratio
    }

    .body-btns{
      display: flex;
      justify-content: space-evenly;
    }
  }

  .sub_title_font{
    font-size: 1.8rem;
    font-weight: 600;
  }

  .cards_wrapper_industry{
    position: relative;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  .cards{
    display: flex;
    gap: 1rem;

    .card {
      display: flex;
      padding: var(--space-small);
      align-items: center;
      // gap: 1rem;
      min-width: 200px;
      max-width: 250px;
      background: var(--w-500);
      color: #fff;

      &:hover{
        cursor: pointer;
        box-shadow: 0 0 0 3px #b5ff00;
      }

      .title{
        // color: var(--s-600);
        color: #fff;
        display: flex;
        font-weight: var(--font-weight-bold);
        line-height: var(--space-two);
        // margin: var(--space-small) 0;
        padding: 0 var(--space-small);
        font-size: var(--font-size-small);
      }

      .card_icon-wraper{
        max-width: 80px;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .card_active{
      box-shadow: 0 0 0 4px #b5ff00;
      .title{
        color: #b5ff00;
      }
    }
  }

  .industry_arrow{
    display: flex;
    align-items: center;
  }
  .industry_arrow button{
    width: auto;
  }
  .industry_arrow button svg{
    fill: #6f1ddf;
  }

  .industry_right_arrow{

  }

  .exist-inbox-wrapper{
    position: absolute;
    display: flex;
    justify-content: center;
    background-color: #fff;
    min-width: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 97%;
    padding: 5em;
    border-radius: 0.4em;

    .inbox-option{
      position: relative;
      display: flex;
      flex-direction: column;
      background: #fff;
      width: fit-content;
      gap: 3em;
      padding: 2em;
      border-radius: 0.4em;
      width: 100% !important;

      .inbox-exist-list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100% !important;
        margin-top: 40px;
      }

      .inbox-new{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
      }

      .loader-wraper{
        display: flex;
        justify-content: center;
        min-height: 200px;
        align-items: flex-end;
      }

      .close_inbox{
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }

}

@media screen and (max-width: 768px) {
  .industry-popup{
    .body_wrap{
      padding: 0;
    }
    .cards{
      display: block;
    }
  }
}

@media screen and (min-width: 768px) {
  .industry-popup{
    .cards{
      display: flex;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {}
@media screen and (min-width: 1366px) and (max-width: 1680px) {}
@media screen and (min-width: 1680px) {}

.inbox_container_wrapper{
  min-height: 250px;
}
.existing_inboxes{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100% !important;
  padding: 3.2rem 3.2rem 0;
}

.inbox_loader_wraper{
  display: flex;
  justify-content: center;
}
/*
.whatsapp-question{
  ol{
    font-size: 1.4rem;
  }
  .btns{
    display: flex;
    gap: 1rem;
    text-align: center;
    button {
      display: inline;
    }
  }
  a{
    text-decoration: underline;
  }
}
.whatsapp-question .tiny_text{
  font-size: 1.02rem;
  color: #6f1ddf;
}
.tiny_text_margin{
  margin-top: 6px;
}
.inbox_create_btn button{
  display: block;
  margin-left: 0;
}
*/
</style>
