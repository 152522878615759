<template>
    <fragment>
        <!-- whatsapp inbox: modal 1 -->
        <modal modalMainContainerClass="instruction_popup" :show.sync="isStepOneOpen" :on-close="temporaryCloseModal">
            <woot-modal-header
                :header-title="$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.TITLE')"
                :header-content="$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.HEADING')"
            />
            <div class="whatsapp-question">
                <div>
                    <ol class="">
                    <li v-for="el in whatsAppCompulsoryList" :key="el.text">
                        <span v-if="el.url"><a :href="el.url" target="_blank">{{el.text}}</a></span>
                        <span v-else>{{el.text}}</span>
                    </li>
                    </ol>
                    <!-- <a href="https://peasy.ai/docs/whatsapp-cloud/" target="_blank">{{$t('INSTRUCTION_POPUP.NEW_WHATSAPP_INBOX.LIST.LEARN')}}</a> -->
                    <p>
                    {{$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.FOOTER.PARA_1ST_PART')}}
                    <a href="https://peasy.ai/docs/whatsapp-cloud/" target="_blank">{{$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.FOOTER.LINK')}}</a>
                    {{$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.FOOTER.PARA_2ND_PART')}}
                    </p>
                    <div class="btns">
                    <div>
                        <div class="inbox_create_btn ">
                        <woot-button classNames="modal_btn" @click="onSkipFn">{{$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.BUTTONS.SKIP.TEXT')}}</woot-button>
                        </div>
                        <p class="tiny_text">{{$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.BUTTONS.SKIP.HINT')}}</p>
                    </div>
                    <div>
                        <div class="inbox_create_btn">
                        <woot-button classNames="modal_btn" @click="onCloseStepOneFn">{{$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.BUTTONS.OK.TEXT')}}</woot-button>
                        </div>
                        <p class="tiny_text">{{$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.BUTTONS.OK.HINT')}}</p>
                    </div>
                    </div>
                </div>
            </div>
        </modal>
        <!-- whatsapp inbox: modal 2 caution -->
        <modal :show.sync="isStepTwoOpen" :on-close="temporaryCloseModal">
            <woot-modal-header
                header-title="Caution"
                header-content="You’ll need a new phone number that’s not associated with any existing WhatsApp account to register a new WhatsApp API account. Do you have a new phone number ready?"
            />
            <div class="whatsapp-body">
                <select name="" id="" @change="onchangeCautionOption($event)" :value="selectedCaution">
                    <option v-for="item in cautionDropdown" :value="item.value" :key="item.value">{{item.value}}</option>
                </select>
                <woot-button :is-loading="isStepLoading" @click="cautionHandler">Continue</woot-button>
            </div>
        </modal>
        <!-- whatsapp inbox: modal 3 Gift-->
        <modal :show.sync="isStepThreeOpen" :on-close="temporaryCloseModal">
            <woot-modal-header
                header-title="FREE Samsung Galaxy A14 5G smartphone*"
                header-content=""
            />
            <div class="whatsapp-body">
                <img :src="GiftImgUrl" alt="gift image">
                <p style="margin-top:8px;">* Get a new 5G line from Maxis for only RM 69 a month and get a free Samsung Galaxy A14 5G smartphone when you apply.</p>
                <div style="margin-top:8px;">
                    <woot-button :is-loading="isStepLoading"  @click="moreGiftHandler">Find Out More</woot-button>
                    <woot-button :is-loading="isStepLoading"  @click="giftHandler">Continue</woot-button>
                </div>
            </div>
        </modal>
    </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import endPoints from '../../../../api/endPoints';
import Modal from '../../../../components/Modal.vue';
import GiftImgUrl from "../../../../assets/images/external/whatsapp_inbox_gift.png";
// import GiftImgUrlX from "../../../../assets/images/channels/line.png";
import {popupHelpers} from "../../../../helper/demoAutomationHelper"

export default {
    components:{
        Modal,
    },
    props:{
        isStepOneOpen:{
            type: Boolean,
            required: true,
            default: false,
        },
        onCloseStepOneFn:{
            type: Function,
            required: true,
            default: ()=>{}
        },
        isStepTwoOpen:{
            type: Boolean,
            required: true,
            default: false,
        },
        onCloseStepTwoFn:{
            type: Function,
            required: true,
            default: ()=>{}
        },

        isStepThreeOpen:{
            type: Boolean,
            required: true,
            default: false,
        },
        onCloseStepThreeFn:{
            type: Function,
            required: true,
            default: ()=>{}
        },

        onSkipFn:{
            type: Function,
            required: true,
            default: ()=>{}
        },
        selectedCaution:{
            type: String,
            required: true,
            default: 'yes',
        },
        onSelectCautionOptionFn:{
            type: Function,
            required: true,
            default: ()=>{}
        },
    },
    data(){
        return {
            isStepLoading:false,
            whatsAppCompulsoryList:[
                {
                    // text: this.$t('INSTRUCTION_POPUP.NEW_WHATSAPP_INBOX.LIST.VALID_MOBILE'),
                    text: this.$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.COMPULSORY_LIST.PHONE'),
                },
                {
                    text: this.$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.COMPULSORY_LIST.FACEBOOK'),
                    url:"https://peasy.ai/docs/how-to-setup-my-facebook-business/",
                },
                {
                    text: this.$t('INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.COMPULSORY_LIST.CARD'),
                },
            ],
            cautionDropdown:[
                {label:"No",value:"no"},
                {label:"Yes",value:"yes"},
            ],
            GiftImgUrl,
        }
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
        }),
    },
    methods:{
        setupWhatsAppInbox(){
            const url = endPoints('whatsappAccountSetup').url(this.currentUser.account_id);
            this.$router.push(url);
        },
        async cautionHandler(){
            console.log({
                selectedCaution:this.selectedCaution,
            });

            this.isStepLoading=true;
            window.$peasy?.setUser(this.currentUser.email,{
                name: this.currentUser.fullName,
                email: this.currentUser.email,
            })
            await popupHelpers.sleep(2000);

            if (this.selectedCaution === 'yes') {
                window.$peasy?.setCustomAttributes({
                    maxis:"dq"
                })
                await popupHelpers.sleep(2000);
                // go to whatsapp create page
                this.setupWhatsAppInbox();
            }else{
                // go next step
                this.onCloseStepTwoFn();
            }
            this.isStepLoading=false;
        },
        async giftHandler(){
            console.log({selectedCaution:this.selectedCaution});
            this.isStepLoading=true;
            if (this.selectedCaution === 'no') {
                window.$peasy?.setCustomAttributes({
                    maxis:"lost"
                })
                await popupHelpers.sleep(2000);
            }
            this.onCloseStepThreeFn();
            this.isStepLoading=false;
        },
        async moreGiftHandler(){
            console.log({selectedCaution:this.selectedCaution});
            this.isStepLoading=true;
            if (this.selectedCaution === 'no') {
                window.$peasy?.setCustomAttributes({
                    maxis:"interested"
                })
                await popupHelpers.sleep(2000);
            }

            // open the ref page
            const anchor = document.createElement('a');
            anchor.href = 'https://www.business.maxis.com.my/en/plans/postpaid-plan/';
            anchor.target = '_blank';
            anchor.click();

            this.onCloseStepThreeFn();
            this.isStepLoading=false;
        },
        temporaryCloseModal(){
            this.isStepOneOpen = false;
            this.isStepTwoOpen = false;
            this.isStepThreeOpen = false;
        },
        onchangeCautionOption(event){
            console.log(event);
            this.onSelectCautionOptionFn(event.target.value);
        },
    },
}
</script>

<style lang="scss" scoped>

.whatsapp-question{
    padding: 1.2rem 3.2rem 3.2rem 3.2rem;

    ol{
        font-size: 1.4rem;
    }
    .btns{
        display: flex;
        gap: 1rem;
        text-align: center;
        button {
        display: inline;
        }
    }
    a{
        text-decoration: underline;
    }
}
.whatsapp-question .tiny_text{
  font-size: 1.02rem;
  color: #6f1ddf;
}
.tiny_text_margin{
  margin-top: 6px;
}
.inbox_create_btn {
    button{
        display: block;
        margin-left: 0;
    }
}

.whatsapp-body{
    padding: 3.2rem;
}
</style>