<template>
  <section class="contacts-table-wrap">
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 11.4rem)"
      scroll-width="187rem"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"

    />

    <empty-state
      v-if="!isLoading && !usagesOrderReport"
      title="There are no available records"
    />
    <div v-if="isLoading" class="contacts--loader">
      <spinner />
      <span>{{ $t('FLOW.LOADING') }}</span>
    </div>
  </section>
</template>


<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';

import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import timeMixin from 'dashboard/mixins/time';

export default {
    components: {
        EmptyState,
        Spinner,
        VeTable,
    },
    mixins: [clickaway, timeMixin],
    props: {
        usagesOrderReport: {
            type: Object,
            default: () => null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        tableData() {
            if (this.isLoading) {
                return [];
            }
            if (Array.isArray(this.usagesOrderReport)) {
                return this.usagesOrderReport;
            }
            return [];
        },
        columns() {
            return [
                {
                    field: 'id',
                    key: 'id',
                    title: 'Payment ID',
                    align: 'left',
                    fixed: 'left',
                    width: 180,
                },
                {
                    field: 'order_id',
                    key: 'order_id',
                    title: 'Order ID',
                    align: 'center',
                    width: 180,
                },
                {
                    field: 'amount',
                    key: 'amount',
                    title: 'Amount (MYR)',
                    align: 'right',
                    width: 150,
                  renderBodyCell: ({row})=>{
                    const sign = row.kind === 'refund' ? "- " : "";
                    return `${sign}${parseFloat(row.amount).toFixed(2)}`
                  }
                },
                {
                    field: 'completed',
                    key: 'completed',
                    title: 'Completed',
                    align: 'center',
                    width: 100,
                    renderBodyCell: ({row})=>{
                        return <span>{row.completed ? "Yes" : "No"}</span>
                    }
                },

                {
                    field: 'order_total',
                    key: 'order_total',
                    title: 'Order Total (MYR)',
                    align: 'center',
                    width: 180,
                },
                {
                    field: 'created_at',
                    key: 'created_at',
                    title: 'Created At',
                    align: 'center',
                    width: 210,
                    renderBodyCell: ({row})=>{
                        try {
                            const date = new Date(row.created_at);
                            const formattedDate = new Intl.DateTimeFormat('en-US', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                            }).format(date);
                            return <span>{formattedDate}</span>
                        } catch (error) {
                            console.log(error);
                            return <span></span>
                        }
                    }
                },
                {
                    field: 'message',
                    key: 'message',
                    title: 'Message',
                    align: 'left',
                }
            ];
        },
        // end computed
    },
    mounted() {},
    methods: {},
}
</script>



<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.contacts-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}
.ve-table {
  .ve-table-container {
    overflow-y: hidden !important;
  }
}

.contacts-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }
  .row--user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      min-width: 0;
    }

    .user-thumbnail-box {
      margin-right: var(--space-small);
    }

    .user-name {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      margin: 0;
      text-transform: capitalize;
    }

    .view-details--button {
      color: var(--color-body);
    }

    .user-email {
      margin: 0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
}

.contacts--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.cell--social-profiles {
  a {
    color: var(--s-300);
    display: inline-block;
    font-size: var(--font-size-medium);
    min-width: var(--space-large);
    text-align: center;
  }
}
</style>
